import {css} from '@emotion/core';
import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Button, Form} from 'semantic-ui-react';
import {AssessmentsService} from '../../api/generated';
import {Courses} from '../../api/generated/enums';

import {useOrganizationSlug} from '../../auth/use-auth';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {notifications} from '../../utils/notification-service';

export const StartAssessment = ({
  assessmentPassPercentage,
  chapterId,
  course,
  userName,
}) => {
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const organizationSlug = useOrganizationSlug();
  const history = useHistory();
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
    chapterId: string;
  }>();
  const courseId = Number(match.params.courseId);
  const enrollmentId = Number(match.params.enrollmentId);

  const startAssessmentAttempt = async () => {
    if (!enrollmentId) {
      return;
    }

    let response;

    if (chapterId) {
      response = await AssessmentsService.beginChapterAssessmentAttempt({
        id: enrollmentId,
        chapterId: chapterId,
      });
    } else {
      response = await AssessmentsService.beginCourseAssessmentAttempt({
        id: enrollmentId,
      });
    }

    if (response.hasErrors) {
      notifications.errors(response.errors);
    }

    if (response?.data) {
      const assessmentId = response?.data?.assessmentAttemptId ?? 0;

      history.push(
        buildPath(routes.organization.course.assessment, {
          slug: organizationSlug,
          courseId,
          enrollmentId,
          assessmentId,
        })
      );
    }
  };

  const termAudioSource =
    course.name === Courses.Boating
      ? chapterId
        ? 'https://drive.google.com/uc?export=download&id=1rYWJR84Vy0zs7mmMkSlp82_IlU4R36Qf'
        : 'https://drive.google.com/uc?export=download&id=1VkuZlILkg-qgA1PzZyDRFfyf-Y5B-BQb'
      : course.name === Courses.Hunting
      ? chapterId
        ? 'https://drive.google.com/uc?export=download&id=19Gb-vVBJdDKUatY3y3vPMA5RQjDNBXMB'
        : 'https://drive.google.com/uc?export=download&id=1GNNW6YF6VeEilVQLZGz39MwSJv8HU93j'
      : '';

  return (
    <div css={styles}>
      {(course.courseAssessmentTerms || course.chapterAssessmentTerms) && (
        <p>
          {chapterId
            ? course.chapterAssessmentTerms
            : course.courseAssessmentTerms}
        </p>
      )}
      <p>
        Once you begin the assessment, you no longer be able to access the study
        content for this assessment.
      </p>
      <p>
        The passing grade for this assessment is
        <span className="assessment-pass-percentage">
          {assessmentPassPercentage * 100}%
        </span>
      </p>
      <p>
        This assessment is for:
        <span className="recipient-name">{userName}</span>
      </p>
      <p>
        By agreeing, you certify that you are the individual listed above.
        <br />
        You agree that you will not use any informational materials during the
        assessment and will rely only on your knowledge to answer questions.
        <br />
        If either of these conditions is found to be untrue, the certification
        for this course will be revoked.
      </p>
      <Form>
        <Form.Checkbox
          label="I agree"
          checked={hasAgreed}
          onChange={(_, {checked}) => {
            setHasAgreed(!!checked);
          }}
        />
        <Button
          className="action-button"
          floated="right"
          disabled={!hasAgreed}
          primary
          content="Start Assessment"
          onClick={startAssessmentAttempt}
          type="button"
        />
      </Form>
      <br />
      <br />
      <br />
      <audio src={termAudioSource} controls />
    </div>
  );
};

const styles = css`
  .assessment-pass-percentage {
    margin-left: 5px;
    font-weight: bold;
  }

  .action-button {
    margin-bottom: 20px;
  }

  .attempts-remaining {
    float: right;
    padding-right: 10px;
  }
`;
