import React, {useState} from 'react';
import {Checkbox, Modal} from 'semantic-ui-react';
import {UserPermissions} from '../api/generated/enums';
import styled from '@emotion/styled';
import {RequireAuthorization} from '../auth/require-authorization';
import {debugPermissions} from '../auth/permissions';

export const DebugControls = ({button}) => {
  const storedSkipTimers = localStorage.getItem('skip-timers') === 'true';
  const [skipTimers, setSkipTimers] = useState<boolean>(storedSkipTimers);

  const storedDeleteEnrollments =
    localStorage.getItem('delete-enrollments') === 'true';
  const [deleteEnrollments, setDeleteEnrollments] = useState<boolean>(
    storedDeleteEnrollments
  );

  const storedUnlockCourseContent =
    localStorage.getItem('unlock-course-content') === 'true';
  const [unlockCourseContent, setUnlockCourseContent] = useState<boolean>(
    storedUnlockCourseContent
  );

  const DebugControl = ({storageKey, checked, setChecked, label}) => (
    <DebugControlContainer>
      <Checkbox
        toggle
        checked={checked}
        onChange={() => {
          const flippedState = checked ? 'false' : 'true';
          localStorage.setItem(storageKey, flippedState);
          setChecked(!checked);
        }}
        label={label}
      />
    </DebugControlContainer>
  );

  const DebugControlContainer = styled.div`
    margin-bottom: 20px;
  `;
  return (
    <RequireAuthorization permission={debugPermissions}>
      <Modal trigger={button}>
        <Modal.Header>Debug Controls</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <RequireAuthorization
              permission={UserPermissions['Courses: SkipTimers']}
            >
              <DebugControl
                storageKey="skip-timers"
                checked={skipTimers}
                setChecked={setSkipTimers}
                label="Bypass Course Timers"
              />
            </RequireAuthorization>
            <DebugControl
              storageKey="unlock-course-content"
              checked={unlockCourseContent}
              setChecked={setUnlockCourseContent}
              label="Unlock All Course Content"
            />
            <RequireAuthorization
              permission={UserPermissions['Enrollments: Delete']}
            >
              <DebugControl
                storageKey="delete-enrollments"
                checked={deleteEnrollments}
                setChecked={setDeleteEnrollments}
                label="Allow Enrollment Deletion"
              />
            </RequireAuthorization>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </RequireAuthorization>
  );
};
