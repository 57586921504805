import React, {useCallback} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {CategoriesService} from '../../../../api/generated';
import {Form} from '../../../../forms';
import {useNotification} from '../../../../hooks/use-notifications';
import {routes} from '../../../../routes';
import {buildPath} from '../../../../routes/utils';

export const CourseCategoryDetail = () => <div>Details</div>;

export const CourseCategoryCreate = () => {
  const match = useRouteMatch<{courseId: string}>();
  const history = useHistory();
  const courseId = Number(match.params.courseId);
  const notifications = useNotification();

  const onSubmit = useCallback(
    async (values) => {
      const response = await CategoriesService.create({
        body: {courseId, ...values},
      });

      if (response.hasErrors) {
        return response;
      }

      notifications.success('Category created');
      history.push(
        buildPath(routes.courses.detail.categories.listing, {id: courseId})
      );
    },
    [courseId, history, notifications]
  );

  return (
    <Form.Container>
      <Form
        onSubmit={onSubmit}
        render={() => (
          <>
            <Form.Row>
              <Form.Input fieldName="name" fieldLabel="Name" />
              <Form.Input
                fieldName="minimumQuestionsCount"
                fieldLabel="Minimum Questions Count"
              />
            </Form.Row>

            <div className="form-actions">
              <Form.Button type="submit" primary>
                Create Category
              </Form.Button>
              <Form.Button
                as={Link}
                to={buildPath(routes.courses.detail.categories.listing, {
                  id: courseId,
                })}
              >
                Cancel
              </Form.Button>
            </div>
          </>
        )}
      />
    </Form.Container>
  );
};
