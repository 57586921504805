import css from '@emotion/css/macro';
import {faPoll} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBars,
  faChalkboardTeacher,
  faSchool,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons';
import {faUserCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {lighten} from 'polished';
import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Dropdown, Menu, Modal, Responsive} from 'semantic-ui-react';
import {Logo} from './assets/ldwf-logo';
import {userManager} from './auth';
import {ChangePassword} from './auth/change-password';
import {userIsAdmin} from './auth/use-auth';
import {DebugControls} from './components/debug-controls';
import {routes} from './routes';
import {themeColors} from './styles';
import {Breakpoints, Media} from './styles/breakpoints';
import {User} from './types';

type PrimaryNavigation = {
  user: User;
};

export const PrimaryNavigation: React.FC<PrimaryNavigation> = ({user}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const hasDebugPermission = true;
  //TODO: REPLACE THIS PERMISSION!!
  // useRequireAuthorization({
  //   permission: debugPermissions,
  // });

  return (
    <>
      <Menu secondary className="top-navigation" css={styles}>
        <Responsive minWidth={Breakpoints.TabletMin} as={React.Fragment}>
          {userIsAdmin(user) ? (
            <Menu.Item
              as={Link}
              to={routes.dashboard}
              className="logo-container"
            >
              <LogoAndText />
            </Menu.Item>
          ) : (
            <Menu.Item className="logo-container">
              <LogoAndText />
            </Menu.Item>
          )}
        </Responsive>
        {userIsAdmin(user) && (
          <>
            <Responsive maxWidth={Breakpoints.MobileMax} as={React.Fragment}>
              <MobileNavigation />
            </Responsive>
            <Responsive minWidth={Breakpoints.TabletMin} as={React.Fragment}>
              <DesktopNavigation />
            </Responsive>
          </>
        )}
        <Menu.Menu position="right">
          <Dropdown
            item
            className="user-icon-container"
            trigger={
              <span title={user.name}>
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="user-icon"
                ></FontAwesomeIcon>
                {user.name}
              </span>
            }
            icon={null}
          >
            <Dropdown.Menu>
              {userIsAdmin(user) && (
                <>
                  <Dropdown.Item onClick={() => setShowChangePassword(true)}>
                    Change Password
                  </Dropdown.Item>
                </>
              )}
              {hasDebugPermission && (
                <DebugControls
                  button={
                    <Dropdown.Item onClick={() => {}}>
                      Debug Controls
                    </Dropdown.Item>
                  }
                />
              )}
              <Dropdown.Item
                onClick={() => {
                  userManager.signoutRedirect();
                }}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      <Modal
        size="small"
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      >
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
          <ChangePassword
            onComplete={() => {
              setShowChangePassword(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const LogoAndText = () => {
  return (
    <>
      <Logo className="logo" />
      <div>
        <div className="logo-text">Department of Wildlife and Fisheries</div>
        <div className="logo-subtext">State of Louisiana</div>
      </div>
    </>
  );
};

const DesktopNavigation = () => (
  <Menu secondary role="navigation" className="desktop-navigation" size="large">
    <Menu.Item as={NavLink} to={routes.organization.landing}>
      <FontAwesomeIcon icon={faChalkboardTeacher} /> Courses
    </Menu.Item>

    <Menu.Item as={NavLink} exact to={routes.courses.results}>
      <FontAwesomeIcon icon={faPoll} /> Course Results
    </Menu.Item>

    <Menu.Item as={NavLink} exact to={routes.courses.listing}>
      <FontAwesomeIcon icon={faSchool} /> Manage Courses
    </Menu.Item>

    <Menu.Item as={NavLink} to={routes.users.listing}>
      <FontAwesomeIcon icon={faUsers} /> Users
    </Menu.Item>
  </Menu>
);

const MobileNavigation: React.FC = () => (
  <Dropdown
    className="mobile-navigation"
    item
    trigger={
      <span>
        <FontAwesomeIcon icon={faBars} size="lg" />
      </span>
    }
    icon=""
  >
    <Dropdown.Menu>
      <Menu.Item as={NavLink} to={routes.organization.landing}>
        <FontAwesomeIcon icon={faChalkboardTeacher} /> Courses
      </Menu.Item>

      <Menu.Item as={NavLink} exact to={routes.courses.results}>
        <FontAwesomeIcon icon={faPoll} /> Course Results
      </Menu.Item>

      <Menu.Item as={NavLink} exact to={routes.courses.listing}>
        <FontAwesomeIcon icon={faSchool} /> Manage Courses
      </Menu.Item>

      <Menu.Item as={NavLink} to={routes.users.listing}>
        <FontAwesomeIcon icon={faUsers} /> Users
      </Menu.Item>
    </Dropdown.Menu>
  </Dropdown>
);

const styles = css`
  &.top-navigation.ui.secondary.menu {
    box-shadow: rgb(87, 99, 105) 2px 0px 5px -2px;
    z-index: 3;
    margin: 0px;
    border-bottom: 5px solid #8F3C1F};

    ${Media('TabletMax')} {
      padding: 0 10px 0 0;
    }

    .ui.header {
      color: ${themeColors.gray600};
      font-weight: normal;
    }

    .ui.dropdown .menu {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    }

    .logo-container {
      padding: 0px 16px 0px 70px;
      margin: 8px 0px;

      .logo {
        width: 65px;
      }

      .logo-text {
        color: ${themeColors.gray100};
        font-size: 1em;
        font-weight: bold;
        margin-left: 20px;
        padding-bottom: 6px;
      }

      .logo-subtext {
        color: ${themeColors.green300};
        font-size: 0.85em;
        margin-left: 20px;
      }
    }

    .ui.secondary.menu.desktop-navigation {
      border-bottom-color: ${themeColors.gray600} !important;

      .item {
        transition: background-color 0.5s ease;
        transition: color 0.5s ease;
        color: ${themeColors.textLabelInverted};
        cursor: pointer;
        text-align: left;
        border-bottom-color: ${themeColors.gray600};
        font-size: 1.15em;

        svg {
          transition: color 0.5s ease;
          width: 1.18em;
          margin: 0 10px 0 0;
          opacity: 0.9;
          color: ${themeColors.gray200};
        }

        &.header {
          margin-top: 10px;
          margin-bottom: 0;
          padding-bottom: 5px;
          cursor: default;
        }

        &:hover:not(.header) {
          color: ${themeColors.gray300};
          background: ${themeColors.green500};
        }

        &.active,
        &.active:hover {
          background-color: ${themeColors.green500};
          color: ${themeColors.gray100};

          > .label {
            background-color: ${themeColors.gray100};
            color: red;
          }

          svg {
            color: ${themeColors.gray100};
          }
        }

        &.dropdown {
          .item.active,
          .item.active:hover {
            border: none;
            background-color: ${themeColors.green300};
          }
        }

        > i.icon {
          float: none;
          margin-right: 10px;
        }

        > .label {
          background-color: red;
        }
      }
    }

    .ui.dropdown.mobile-navigation {
      svg {
        width: 1.18em;
        margin: 0 10px 0 0;
        opacity: 0.9; 
        color: ${themeColors.gray300};
      }
    }

    .user-icon-container.ui.item {
      height: 100%;
      width: 200px;
      display: flex;
      padding: 0;
      margin: 0;
      border-radius: 0;
      color: ${themeColors.gray100} !important;
      border: 1px solid transparent;
      align-items: center;
      justify-content: center;
      line-height: 1.5rem;
      border-left: solid 1px ${themeColors.green800};
      background: ${themeColors.green900};

      &:hover,
      &:focus,
      &:active {
        background: ${lighten(0.01, themeColors.green800)} !important;
        border: 1px solid ${themeColors.gray100} !important;
      }
    }

    .user-icon {
      color: ${themeColors.green200};
      margin-right: 12px;
    }
  }
`;
