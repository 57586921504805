import {AssessmentAttemptDto, QuestionResponseDto} from '../../api/generated';
import {LiteralKeyedObject} from '../../types';
import produce from 'immer';
import {findIndex} from 'lodash';

type ActionTypes =
  | 'SET_ASSESSMENT_ATTEMPT'
  | 'CLEAR_SELECTED_ANSWER'
  | 'SELECT_ANSWER'
  | 'SHOW_NEXT_QUESTION'
  | 'ON_SELECTED_ANSWER_SAVED'
  | 'SHOW_PREVIOUS_QUESTION';

type AssessmentAttemptState = {
  assessmentAttempt?: AssessmentAttemptDto;
  currentQuestion?: QuestionResponseDto;
  remainingQuestionsCount: number;
  currentQuestionNumber: number;
};

type Handler = (state: AssessmentAttemptState, payload: any) => void;
type Handlers = LiteralKeyedObject<ActionTypes, Handler>;

type Action = {
  type: ActionTypes;
  payload?: any;
};

const getUnansweredQuestions = (assessmentAttempt?: AssessmentAttemptDto) => {
  const availableQuestions =
    assessmentAttempt?.questionResponses?.filter((x) => !x.selectedAnswerId) ??
    [];

  return availableQuestions;
};

const handlers: Handlers = {
  SET_ASSESSMENT_ATTEMPT: (state, {assessmentAttempt}) => {
    state.assessmentAttempt = assessmentAttempt;
  },
  SELECT_ANSWER: (state, {selectedAnswerId}) => {
    if (state.currentQuestion) {
      state.currentQuestion.selectedAnswerId = selectedAnswerId;
    }
  },
  CLEAR_SELECTED_ANSWER: (state) => {
    if (state.currentQuestion) {
      state.currentQuestion.selectedAnswerId = undefined;
    }
  },
  SHOW_PREVIOUS_QUESTION: (state) => {
    const currentQuestionIndex = findIndex(
      state.assessmentAttempt?.questionResponses,
      (x) => x.id === state.currentQuestion?.id
    );

    const previousQuestion =
      state.assessmentAttempt?.questionResponses[currentQuestionIndex - 1];
    state.currentQuestionNumber = state.currentQuestionNumber - 1;

    state.currentQuestion = previousQuestion;
  },
  SHOW_NEXT_QUESTION: (state) => {
    if (!state.assessmentAttempt?.questionResponses?.length) {
      return;
    }

    const unansweredQuestions = getUnansweredQuestions(state.assessmentAttempt);
    state.remainingQuestionsCount = unansweredQuestions.length;

    const isInitializing = !state.currentQuestion;

    if (isInitializing) {
      const allQuestionsHaveBeenAnswered =
        state.assessmentAttempt.questionResponses.length &&
        unansweredQuestions.length === 0;

      state.currentQuestionNumber = allQuestionsHaveBeenAnswered
        ? state.assessmentAttempt.questionResponses.length
        : state.assessmentAttempt.questionResponses.length -
          unansweredQuestions.length +
          1;

      if (allQuestionsHaveBeenAnswered) {
        const lastQuestionIndex =
          state.assessmentAttempt?.questionResponses.length - 1;
        const lastQuestion =
          state.assessmentAttempt?.questionResponses[lastQuestionIndex];

        state.currentQuestion = lastQuestion;
      } else {
        const questionToShowIndex = findIndex(
          state.assessmentAttempt?.questionResponses,
          (x) => x.id === unansweredQuestions[0]?.id
        );

        state.currentQuestion =
          state.assessmentAttempt?.questionResponses[questionToShowIndex];
      }
    } else {
      const currentQuestionIndex = findIndex(
        state.assessmentAttempt?.questionResponses,
        (x) => x.id === state.currentQuestion?.id
      );

      state.currentQuestionNumber = state.currentQuestionNumber + 1;

      const nextQuestion =
        state.assessmentAttempt?.questionResponses[currentQuestionIndex + 1];

      state.currentQuestion = nextQuestion;
    }
  },
  ON_SELECTED_ANSWER_SAVED: (state) => {
    if (!state.assessmentAttempt) {
      return;
    }

    state.assessmentAttempt.questionResponses
      .filter((x) => x.id === state.currentQuestion?.id ?? 0)
      .forEach(
        (x) => (x.selectedAnswerId = state.currentQuestion?.selectedAnswerId)
      );
  },
};

export const initialReducerState: AssessmentAttemptState = {
  assessmentAttempt: undefined,
  currentQuestion: undefined,
  remainingQuestionsCount: 0,
  currentQuestionNumber: 0,
};

export const assessmentAttemptReducer = (
  state: AssessmentAttemptState,
  {type, payload}: Action
): AssessmentAttemptState => {
  const handler = handlers[type] || (() => state);

  return produce(state, (draft) => {
    handler(draft, payload);
  });
};
