import css from '@emotion/css/macro';
import React from 'react';
import {Header} from 'semantic-ui-react';

type FormSection = {
  title: string | React.ReactNode;
};

export const FormSection: React.FC<FormSection> = ({title, children}) => (
  <>
    <Header size="medium" className="form-section" css={styles}>
      {title}
    </Header>
    {children}
  </>
);

const styles = css`
  &.ui.header {
    padding-top: 15px;

    &:first-of-type {
      padding-top: 0;
    }
  }
`;
