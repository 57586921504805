import currency from 'currency.js';
import moment from 'moment';
import {parseCurrency, formatCurrency} from '../utils/currency-helpers';
import {getPooledMoment} from '../utils/get-pooled-moment';

export type ValueConverter = {
  parseInput: (input: string) => any;
  format: (value: any) => string;
  equals: (value: any, formattedInput: string) => boolean;
};

export const defaultValueConverter: ValueConverter = {
  format: (x) => x,
  parseInput: (x) => x,
  equals: (value, formattedInput) => value === formattedInput,
};

export const currencyValueConverter: ValueConverter = {
  format: (value) => formatCurrency(value / 100),
  parseInput: (input) => parseCurrency(input),
  equals: (value, input) => value === parseCurrency(input),
};

export const decimalValueConverter: ValueConverter = {
  format: (value) => currency(value * 100).value.toString(),
  parseInput: (input) => {
    const parsedInput = Number(input);
    if (!Number.isFinite(parsedInput)) {
      return 0;
    }
    const precision = getPrecision(parsedInput) + 2;
    return Number((parsedInput / 100).toPrecision(precision));
  },
  equals: (value, input) => value === decimalValueConverter.parseInput(input),
};

export const timeValueConverter: ValueConverter = {
  format: (value) => moment(value || '0', 'LT').format('hh:mm A'),
  parseInput: (input) => getPooledMoment(input || '0', 'LT').format('HH:mm:ss'),
  equals: (value, input) =>
    moment(value, 'LT').format('HH:mm:ss') ===
    getPooledMoment(input, 'LT').format('HH:mm:ss'),
};

function getPrecision(a) {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}
