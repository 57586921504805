import React, {useMemo} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {Divider, Grid} from 'semantic-ui-react';
import {UsersService, CreateUserRequest} from '../../api/generated';
import {AllPermissions, Roles} from '../../api/generated/enums';
import {RequireAuthorization} from '../../auth/require-authorization';
import {BasicPage} from '../../basic-page';
import {AsyncStateContainer} from '../../components/async-state-container';
import {DeleteButton} from '../../components/confirm-button';
import {Form} from '../../forms';
import {ArrayMutators} from '../../forms/field-arrays';
import {
  dropdown,
  fieldConfig,
  getDefaults,
  input,
} from '../../forms/schema-utils';
import {SubmitError} from '../../forms/submit-error';
import {useNotification} from '../../hooks/use-notifications';
import {routes} from '../../routes';

const fields = fieldConfig<CreateUserRequest>({
  firstName: input({
    fieldLabel: 'First Name',
  }),
  lastName: input({
    fieldLabel: 'Last Name',
  }),
  emailAddress: input({
    fieldLabel: 'Email Address',
  }),
  ldwfNumber: input({
    fieldLabel: 'LDWF #',
  }),
  role: dropdown({
    fieldLabel: 'Role',
    inputProps: {
      enum: Roles,
      selection: true,
    },
    defaultValue: Roles['Global Admin'],
  }),

  // This one is not really an input, but I am just using its
  // generated props to use with the FieldArray below
  claims: input({
    fieldLabel: 'Claims',
  }),
});

const mutators = ArrayMutators();

const breadcrumbs = [
  {title: 'Users', url: routes.users.listing},
  {title: 'Create'},
];

export const UserCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const onSubmit = async (values) => {
    const response = await UsersService.create({body: values});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('User Created');
    history.push(routes.users.listing);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          initialValues={getDefaults(fields)}
          mutators={mutators}
          onSubmit={onSubmit}
          render={() => (
            <>
              <FormFields />
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create User
                </Form.Button>
                <Form.Button as={Link} to={routes.users.listing}>
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const UserDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchUser = useAsync(async () => {
    const {data} = await UsersService.getById({
      id,
    });
    return data;
  }, [id]);

  const user = fetchUser.value || undefined;
  const userName = user ? `${user.firstName} ${user.lastName}` : '';

  const breadcrumbs = useMemo(
    () => [{title: 'Users', url: routes.users.listing}, {title: userName}],
    [userName]
  );

  const onSubmit = async (values) => {
    const response = await UsersService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('User Updated');
    history.push(routes.users.listing);
  };

  const [deleteUserState, deleteUser] = useAsyncFn(async () => {
    const response = await UsersService.deleteById({id});
    if (response.hasErrors) {
      notifications.error('Unable to delete user');
    } else {
      notifications.success('User successfully deleted');
      history.push(routes.users.listing);
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <AsyncStateContainer {...fetchUser}>
        <Form.Container>
          <Form
            initialValues={user}
            mutators={mutators}
            onSubmit={onSubmit}
            render={({values}) => (
              <>
                <FormFields />
                <Divider />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update User
                  </Form.Button>
                  <Form.Button as={Link} to={routes.users.listing}>
                    Cancel
                  </Form.Button>

                  <DeleteButton
                    onConfirm={deleteUser}
                    loading={deleteUserState.loading}
                  />
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

const FormFields = () => {
  return (
    <>
      <Grid columns="2" doubling>
        <Grid.Column>
          <Form.Section title="User Details">
            <Form.Row>
              <Form.Input fieldConfig={fields.firstName} />
              <Form.Input fieldConfig={fields.lastName} />
            </Form.Row>

            <Form.Row>
              <Form.Input fieldConfig={fields.emailAddress} />
            </Form.Row>
          </Form.Section>
        </Grid.Column>
        <Grid.Column>
          <RequireAuthorization role="Global Admin">
            <>
              <Form.Section title="Permissions">
                <Form.Row>
                  <Form.Dropdown fieldConfig={fields.role} />
                </Form.Row>

                <Form.Row>
                  <Form.MultiSelectCheckbox
                    fieldName={fields.claims.fieldName}
                    options={AllPermissions.map((x) => x)}
                    fieldLabel={fields.claims.fieldLabel}
                  />
                </Form.Row>
                <SubmitError name={fields.claims.fieldName} />
              </Form.Section>
            </>
          </RequireAuthorization>
        </Grid.Column>
      </Grid>
    </>
  );
};
