import React from 'react';
import {css} from '@emotion/core';
import {Table} from 'semantic-ui-react';

export const AssessmentResults = ({results}) => {
  return (
    <div css={styles}>
      <Table definition className="results-table">
        <Table.Body>
          <Table.Row>
            <Table.Cell content="Assessment Results" />
            <Table.Cell
              content={`${results.questionsAnsweredCorrectlyCount} out of
        ${results.totalQuestionsCount} ${
                results.totalQuestionsCount === 1 ? 'question' : 'questions'
              } answered correctly`}
            />
          </Table.Row>
          <Table.Row>
            <Table.Cell content="Percentage Results" />
            <Table.Cell content={`${results.percentCorrect}%`} />
          </Table.Row>
          <Table.Row>
            <Table.Cell content="Score Required To Pass" />
            <Table.Cell
              content={`${results.assessmentPassPercentage * 100}%`}
            />
          </Table.Row>
          <Table.Row>
            <Table.Cell content="Pass/Fail" />
            <Table.Cell
              positive={results.passed}
              negative={!results.passed}
              content={results.passed ? 'Pass' : 'Fail'}
            />
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

const styles = css`
  @media only screen and (max-width: 767px) {
    .ui.table.results-table {
      margin-top: 1em;

      tr {
        padding-top: 0;
      }
    }
  }
`;
