import css from '@emotion/css/macro';
import {cx} from 'emotion';
import {FormApi} from 'final-form';
import createDecorator from 'final-form-focus';
import {difference, get, isString} from 'lodash';
import {textInputs} from 'polished';
import React, {useCallback, useMemo} from 'react';
import {Form as SemanticForm, StrictFormProps} from 'semantic-ui-react';
import {getNestedKeys, mapErrors} from './map-formal-errors';
import {Response} from '../api/generated';
import {Alert} from '../components/alert';
import {themeColors} from '../styles';
import {Media} from '../styles/breakpoints';

import {
  Form as FinalForm,
  FormProps,
  FormRenderProps,
  useFormState,
  AnyObject,
} from 'react-final-form';

type FinalFormProps<T = AnyObject> = FormProps<T> & {
  disabled?: boolean;
  disableLoading?: boolean;
  className?: string;
  autoComplete?: 'off';
  formProps?: StrictFormProps;
};

type NonFieldErrors<T = AnyObject> = {
  form: FormApi<T>;
  submitErrors?: object;
};

const focusOnErrors = createDecorator();

export function Form<T extends object = AnyObject>(props: FinalFormProps<T>) {
  const {
    className,
    children,
    render,
    onSubmit,
    disabled = false,
    disableLoading = false,
    autoComplete = undefined,
    formProps = {},
    ...finalFormProps
  } = props;
  const renderFn = (children || render) as (
    props: FormRenderProps<T>
  ) => React.ReactNode;

  const _onSubmit = useCallback(
    async (values, form, callback) => {
      const response = await onSubmit(values, form, callback);

      if (response && response.hasErrors) {
        return mapErrors(response as Response<any>);
      }

      return response;
    },
    [onSubmit]
  );

  const decorators = useMemo(() => {
    if (finalFormProps.decorators) {
      return [focusOnErrors, ...finalFormProps.decorators];
    } else {
      return [focusOnErrors];
    }
  }, [finalFormProps.decorators]);

  return (
    <FinalForm<T>
      onSubmit={_onSubmit}
      decorators={decorators}
      {...finalFormProps}
      render={(formRenderProps) => {
        const {
          handleSubmit,
          submitErrors,
          hasSubmitErrors,
          submitting,
          form,
        } = formRenderProps;

        return (
          <SemanticForm
            {...formProps}
            onSubmit={handleSubmit}
            error={hasSubmitErrors}
            loading={!disableLoading && submitting}
            className={cx(className, {disabled})}
            css={styles}
            autoComplete={autoComplete}
          >
            <NonFieldErrors<T> {...{form, submitErrors}} />
            {renderFn(formRenderProps)}
          </SemanticForm>
        );
      }}
    />
  );
}

const ErrorMessage = ({name}) => {
  const formState = useFormState();
  const errors = formState.submitErrors || {};
  if (errors[name] && isString(errors[name])) {
    return <Alert negative>{errors[name]}</Alert>;
  }
  return null;
};

Form.ErrorMessage = ErrorMessage;

function NonFieldErrors<T = AnyObject>({
  form,
  submitErrors,
}: NonFieldErrors<T>) {
  const nonFieldErrors = useMemo(() => {
    const fields = form.getRegisteredFields();

    const errorKeys = getNestedKeys(submitErrors);
    return difference(errorKeys, fields);
  }, [form, submitErrors]);

  if (!nonFieldErrors.length || !submitErrors) {
    return null;
  }

  return (
    <Alert negative>
      <ul>
        {nonFieldErrors.map((errorKey) => (
          <li key={errorKey}>{get(submitErrors, errorKey)}</li>
        ))}
      </ul>
    </Alert>
  );
}

const fullWidthFields = css`
  .form-row {
    display: block;

    .form-field {
      flex: 1 1 0%;
      margin: 0 0 1rem 0;
    }
  }

  .form-field {
    width: 100%;
  }
`;

const styles = css`
  &.ui.form {
    .ui.medium.header {
      font-weight: 500;
      margin-bottom: 1.5rem;
    }

    .form-row {
      width: 100%;
      display: flex;

      .form-field {
        flex: 1 1 0%;
        margin: 0 0 1rem 0;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
      }
    }

    .form-field {
      margin-bottom: 1rem;
      :not(&.fluid) > & {
        width: calc(50% - 0.75rem);
      }
    }

    td > .form-field {
      width: 100%;
    }

    .sui-error-message {
      display: block;
      color: rgb(159, 58, 56);
      font-size: 0.85rem;
      margin-left: 0.2rem;
    }

    .error.field + .sui-error-message {
      margin-top: -0.9em;
      margin-left: 0.2rem;
    }

    .form-field-hint {
      display: block;
      margin: -0.9rem 0 0 0.2rem;
      font-size: 0.85rem;
      color: #666;
      max-width: 100%;
    }

    .field > label {
      color: ${themeColors.textLabel};
      font-weight: bold;
    }

    .form-actions {
      margin-top: 2rem;
    }

    &.disabled {
      .form-actions {
        display: none;
      }

      .disabled.field {
        opacity: 1;

        > label {
          opacity: 1;
        }
      }

      ${textInputs()} {
        background: #f1f1f1;
        color: #6b6b6b;
        &:focus {
          outline: none;
          background: #f1f1f1;
          color: #6b6b6b;
        }

        .dropdown,
        .selection.dropdown {
          background: #f1f1f1;
          color: #6b6b6b;
          &:focus {
            outline: none;
            background: #f1f1f1;
            color: #6b6b6b;
          }
        }
      }
    }

    ${Media('MobileMax')} {
      ${fullWidthFields};
    }

    .ui.modal > .content > & {
      ${fullWidthFields};
    }
  }
`;
