import moment, {Moment} from 'moment';

const momentPool = new Map<string, Moment>();

export function getPooledMoment(
  dayString: string | Date | number,
  format?: string
) {
  const key = `${dayString}|${format || ''}`;
  if (!momentPool.has(key)) {
    momentPool.set(key, moment(dayString, format));
  }

  return momentPool.get(key) as Moment;
}
