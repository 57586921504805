import React, {ComponentState, useEffect} from 'react';
import {AssessmentsService} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {
  PagedDataTableConfig,
  usePagedDataTable,
} from '../../hooks/use-paged-data-table';
import {Form} from '../../forms';
import {useProduce} from '../../hooks/use-produce';
import {DateRangeCustomField} from '../../forms/adapters/datepicker';
import moment from 'moment';

const getUtcDateWithTimeOffset = (date) => {
  if (!date) {
    return null;
  }

  const dateWithCurrentTime = moment(
    `${new Date(date).toLocaleDateString()} ${new Date().toLocaleTimeString()}`
  );

  const utcDate = new Date(`${dateWithCurrentTime}`).toISOString();

  return utcDate;
};

export const CourseResultListing = () => {
  const [state, setState] = useProduce<ComponentState>({
    filter: {
      assessmentTakenOnOrAfterDate: new Date(),
      assessmentTakenOnOrBeforeDate: new Date(),
    },
    offsetFilter: {
      assessmentTakenOnOrAfterDate: new Date(),
      assessmentTakenOnOrBeforeDate: new Date(),
    },
  });

  useEffect(() => {
    setState((draft) => {
      draft.offsetFilter.assessmentTakenOnOrAfterDate = getUtcDateWithTimeOffset(
        state.filter.assessmentTakenOnOrAfterDate
      );
      draft.offsetFilter.assessmentTakenOnOrBeforeDate = getUtcDateWithTimeOffset(
        state.filter.assessmentTakenOnOrBeforeDate
      );
    });
  }, [state.filter, setState]);

  const pagedDataTable = usePagedDataTable(
    AssessmentsService.getAssessmentAttemptResults,
    TableConfig,
    {
      additionalParams: state.offsetFilter,
      actions: (
        <TableFilters
          initialValues={state.filter}
          onApply={(filters) => {
            setState((draft) => {
              draft.filter.assessmentTakenOnOrAfterDate =
                filters.assessmentTakenOnOrAfterDate;
              draft.filter.assessmentTakenOnOrBeforeDate =
                filters.assessmentTakenOnOrBeforeDate;
            });
          }}
        />
      ),
    }
  );

  return <BasicPage title="Course Results">{pagedDataTable}</BasicPage>;
};

const TableFilters = ({initialValues, onApply}) => {
  return (
    <Form
      styles
      initialValues={initialValues}
      onSubmit={(values) => {
        onApply(values);
      }}
      render={() => (
        <>
          <label>Dates Taken</label>
          <DateRangeCustomField
            startDateProperty="assessmentTakenOnOrAfterDate"
            endDateProperty="assessmentTakenOnOrBeforeDate"
            submitOnChange={true}
            showQuickSelection={false}
          />
        </>
      )}
    />
  );
};

const TableConfig = PagedDataTableConfig(
  AssessmentsService.getAssessmentAttemptResults,
  {
    columns: [
      {
        header: 'Customer Name',
        render: (item) => `${item.userFirstName} ${item.userLastName}`,
        sortable: 'userFirstName',
      },
      {
        header: 'LDWF #',
        column: 'userLdwfNumber',
      },
      {
        header: 'Course Name',
        column: 'courseName',
        sortable: 'courseName',
      },
      {
        header: 'Date Taken',
        render: (item) =>
          `${moment(item.dateTaken).format('MM/DD/yyyy hh:mm A')}`,
        sortable: 'dateTaken',
      },
      {
        header: 'Pass/Fail',
        render: (item) => (item.passed ? <div>Pass</div> : <div>Fail</div>),
      },
    ],
    defaultSort: {
      column: 'dateTaken',
      direction: 'DESC',
    },
    searchFieldNames: [
      'userLdwfNumber',
      'userLastName',
      'userFirstName',
      'courseName',
    ],
  }
);
