// Enums

export type ClientIds = keyof typeof ClientIds;
export const ClientIds = {web: 'web'} as const;

export type CustomClaims = keyof typeof CustomClaims;
export const CustomClaims = {permissions: 'permissions', role: 'role'} as const;

export type CurrencyCodes = keyof typeof CurrencyCodes;
export const CurrencyCodes = {USD: 'USD'} as const;

export type Timezones = keyof typeof Timezones;
export const Timezones = {
  'US/Pacific': 'US/Pacific',
  'US/Mountain': 'US/Mountain',
  'US/Central': 'US/Central',
  'US/Eastern': 'US/Eastern',
} as const;

export type UriProtocols = keyof typeof UriProtocols;
export const UriProtocols = {
  'http://': 'http://',
  'https://': 'https://',
} as const;

export type CompletionStatuses = keyof typeof CompletionStatuses;
export const CompletionStatuses = {
  'Not Started': 'Not Started',
  'In Progress': 'In Progress',
  Passed: 'Passed',
  Failed: 'Failed',
} as const;

export type CourseTypes = keyof typeof CourseTypes;
export const CourseTypes = {Education: 'Education', Civil: 'Civil'} as const;

export type Courses = keyof typeof Courses;
export const Courses = {
  Hunting: 'Hunting',
  Boating: 'Boating',
  Class1: 'Class1',
  Class2: 'Class2',
  Class2A: 'Class2A',
} as const;

export type ContentContainerTypes = keyof typeof ContentContainerTypes;
export const ContentContainerTypes = {
  Chapter: 'Chapter',
  Section: 'Section',
  Page: 'Page',
  Assessment: 'Assessment',
} as const;

export type AssessmentAttemptTypes = keyof typeof AssessmentAttemptTypes;
export const AssessmentAttemptTypes = {
  Chapter: 'Chapter',
  Final: 'Final',
} as const;

export type Integrations = keyof typeof Integrations;
export const Integrations = {'[object Object]': '[object Object]'} as const;

export type SignalRTopics = keyof typeof SignalRTopics;
export const SignalRTopics = {} as const;

export type SignalRConfig = keyof typeof SignalRConfig;
export const SignalRConfig = {'/signalr-hub': '/signalr-hub'} as const;

export type UserPermissions = keyof typeof UserPermissions;
export const UserPermissions = {
  'Users: View': 'Users: View',
  'Users: Manage': 'Users: Manage',
  'Courses: SkipTimers': 'Courses: SkipTimers',
  'Enrollments: View': 'Enrollments: View',
  'Enrollments: Create': 'Enrollments: Create',
  'Enrollments: Delete': 'Enrollments: Delete',
  'Courses: Manage': 'Courses: Manage',
} as const;

export type Roles = keyof typeof Roles;
export const Roles = {
  'System User': 'System User',
  'Global Admin': 'Global Admin',
  'Accreditation User': 'Accreditation User',
  'Course Manager': 'Course Manager',
  User: 'User',
} as const;

// Statics

export const RoleTemplates = {
  'Global Admin': [
    'Users: View',
    'Users: Manage',
    'Courses: SkipTimers',
    'Enrollments: View',
    'Enrollments: Create',
    'Enrollments: Delete',
    'Courses: Manage',
  ],
} as const;

export const AllPermissions = [
  'Users: View',
  'Users: Manage',
  'Courses: SkipTimers',
  'Enrollments: View',
  'Enrollments: Create',
  'Enrollments: Delete',
  'Courses: Manage',
] as const;
