export const routes = {
  routeResolver: '/',
  callback: '/callback',

  dashboard: '/admin/',
  adminBase: '/admin',
  users: {
    create: '/admin/users/create',
    detail: '/admin/users/:id',
    listing: '/admin/users',
  },
  courses: {
    listing: '/admin/courses',
    detailBase: '/admin/courses/:id',
    detail: {
      assessment: '/admin/courses/:id/assessment',
      categories: {
        listing: '/admin/courses/:id/categories',
        create: '/admin/courses/:courseId/categories/create',
        detail: '/admin/courses/:courseId/categories/:id',
      },
      content: '/admin/courses/:id/content',
    },
    results: '/admin/courses/results',
  },
  settings: {
    detail: '/admin/settings/:id',
    listing: '/admin/settings',
  },
  organization: {
    base: '/ldwf',
    landing: '/ldwf/landing',
    course: {
      content: '/ldwf/course/:courseId/enrollments/:enrollmentId/content',
      contentStart:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/content/details',
      details: '/ldwf/course/:courseId/details',
      assessment:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/assessment/:assessmentId',
      assessmentResults:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/assessment/:assessmentAttemptId/results',
      chapterAssessmentStart:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/chapter/:chapterId/chapterAssessment/details',
      courseAssessmentStart:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/courseAssessment/details',
      assessmentSubmit:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/assessment/:assessmentAttemptId/submit',
      enrollmentResults:
        '/ldwf/course/:courseId/enrollments/:enrollmentId/results',
    },
  },
};
