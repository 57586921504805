import css from '@emotion/css/macro';
import {
  faExclamationTriangle,
  faInfoCircle,
  faLayerGroup,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {remove} from 'lodash';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Dropdown, Header, Input, Segment} from 'semantic-ui-react';
import {
  CategoriesService,
  AssessmentsService,
  QuestionDto,
} from '../../../../api/generated';
import {AsyncStateContainer} from '../../../../components/async-state-container';
import {Form} from '../../../../forms';
import {useNotification} from '../../../../hooks/use-notifications';
import {routes} from '../../../../routes';
import {buildPath} from '../../../../routes/utils';
import AssessmentQuestion from './assessment-editor-question';

type SortableQuestionDto = {
  orderNumber: number;
} & QuestionDto;

const initialAssessment = {
  id: 0,
  assessmentId: 0,
  questions: [],
  editedQuestions: [],
};

const getEmptyQuestion = (
  categoryId: number,
  numberOfQuestions: number
): SortableQuestionDto => ({
  id: 0,
  orderNumber: numberOfQuestions,
  categoryId,
  courseTemplateId: 0,
  isEnabled: true,
  value: '',
  answers: [
    {isCorrectAnswer: true, value: ''},
    {isCorrectAnswer: false, value: ''},
    {isCorrectAnswer: false, value: ''},
    {isCorrectAnswer: false, value: ''},
  ],
});

const EDIT_QUESTION = 'EDIT_QUESTION';
const ADD_BLANK_QUESTION = 'ADD_BLANK_QUESTION';
const SET_CATEGORY_QUESTIONS = 'SET_CATEGORY_QUESTIONS';

const assessmentReducer = (state, action) => {
  if (action.type === EDIT_QUESTION) {
    const updatedQuestions = state.questions.map((question) =>
      question.orderNumber === action.payload.question.orderNumber
        ? {...action.payload.question}
        : question
    );

    const updatedEditedQuestions = state.questions.reduce((acc, curr) => {
      if (curr.orderNumber === action.payload.question.orderNumber) {
        remove(
          acc,
          (x: any) => x.orderNumber === action.payload.question.orderNumber
        );

        return [...acc, {...action.payload.question}];
      } else return acc;
    }, state.editedQuestions);

    return {
      ...state,
      questions: updatedQuestions,
      editedQuestions: updatedEditedQuestions,
    };
  }

  if (action.type === ADD_BLANK_QUESTION) {
    return {
      ...state,
      questions: [...state.questions, action.payload],
    };
  }

  if (action.type === SET_CATEGORY_QUESTIONS) {
    var orderedQuestions = action.payload.map((x, i) => ({
      ...x,
      orderNumber: i + 1,
    }));

    return {
      ...state,
      questions: [...orderedQuestions],
    };
  }

  return state;
};

const CourseAssessmentEditor = () => {
  const [categoryOptions, setCategoryOptions] = useState<
    {text: string; value: string}[]
  >([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
  const [_assessment, dispatch] = useReducer(
    assessmentReducer,
    initialAssessment
  );
  const [filteredQuestions, setFilteredQuestions] = useState<
    SortableQuestionDto[]
  >([]);
  const [search, setSearch] = useState('');
  const match = useRouteMatch<{id: string}>();
  const courseId = Number(match.params.id);
  const notifications = useNotification();

  useEffect(() => {
    if (_assessment?.questions.length) {
      let questionsToShow = _assessment.questions;

      if (search) {
        questionsToShow = questionsToShow
          .filter((question) =>
            question?.value.toUpperCase().includes(search.toUpperCase())
          )
          .sort((x, y) => x.orderNumber < y.orderNumber);
      }

      setFilteredQuestions(questionsToShow);
    }
  }, [search, _assessment]);

  const fetchCategories = useAsync(async () => {
    const {data} = await CategoriesService.getAll({
      courseId,
    });
    return data;
  }, [courseId]);

  useEffect(() => {
    if (fetchCategories.value) {
      var fetchedCategoryOptions = fetchCategories.value.items.map((x) => ({
        text: x.name,
        value: `${x.id}`,
      }));

      setCategoryOptions(fetchedCategoryOptions);

      if (!selectedCategoryId && fetchedCategoryOptions.length) {
        setSelectedCategoryId(fetchedCategoryOptions[0].value);
      }
    }
  }, [fetchCategories.value, selectedCategoryId]);

  const fetchCategoryQuestions = useAsync(async () => {
    const {data} = await AssessmentsService.getCategoryQuestionsByCourseId({
      courseId,
      categoryId: Number(selectedCategoryId),
    });

    return data;
  }, [courseId, selectedCategoryId]);

  useEffect(() => {
    if (fetchCategoryQuestions.value) {
      dispatch({
        type: SET_CATEGORY_QUESTIONS,
        payload: fetchCategoryQuestions.value,
      });
    }
  }, [fetchCategoryQuestions.value, dispatch]);

  const onSubmit = useCallback(
    async (values) => {
      if (_assessment) {
        const response = await AssessmentsService.createOrUpdateQuestionsByCategoryIdRequest(
          {
            courseId,
            categoryId: Number(selectedCategoryId),
            body: {...values, questions: _assessment.editedQuestions},
          }
        );

        _assessment.editedQuestions = [];

        if (response.hasErrors) {
          return response;
        }

        notifications.success('Assessment updated');
      }
    },
    [courseId, notifications, _assessment, selectedCategoryId]
  );

  const updateQuestion = React.useCallback(
    (question) => {
      dispatch({type: EDIT_QUESTION, payload: {question}});
    },
    [dispatch]
  );

  const addNewQuestion = useCallback(() => {
    if (!selectedCategoryId) {
      return;
    }

    dispatch({
      type: ADD_BLANK_QUESTION,
      payload: getEmptyQuestion(
        Number(selectedCategoryId),
        _assessment.questions.length + 1
      ),
    });

    setSearch('');
  }, [_assessment, dispatch, selectedCategoryId]);

  return (
    <AsyncStateContainer {...fetchCategories}>
      {!fetchCategories.loading && (
        <div css={styles}>
          {!!fetchCategories.value?.items.length && (
            <div className="category">
              <span>Course Category</span>
              <Dropdown
                label="Testing"
                selection
                name="categorySelect"
                placeholder="Select Category"
                options={categoryOptions}
                value={selectedCategoryId}
                onChange={(e, {value}) => setSelectedCategoryId(`${value}`)}
              />
            </div>
          )}

          <AsyncStateContainer {...fetchCategoryQuestions}>
            {!fetchCategoryQuestions.loading && (
              <div>
                {_assessment.questions.length > 0 ? (
                  <Form.Container className="questions-container">
                    <div className="actions-container">
                      <Input
                        className="search-input"
                        fluid
                        icon="search"
                        placeholder="Search Questions"
                        value={search}
                        onChange={(_, {value}) => setSearch(value)}
                      />
                      <Button
                        className="add-question-button"
                        content="Add Question"
                        icon="add"
                        primary
                        onClick={addNewQuestion}
                        type="button"
                      />
                    </div>
                    <Form
                      initialValues={_assessment}
                      onSubmit={onSubmit}
                      render={() => (
                        <>
                          <div className="form-questions">
                            {filteredQuestions.map((question) => (
                              <AssessmentQuestion
                                key={question.orderNumber}
                                question={question}
                                updateQuestion={updateQuestion}
                              />
                            ))}
                          </div>

                          <div className="form-actions">
                            <Form.Button type="submit" primary>
                              Update Assessment
                            </Form.Button>
                            <Form.Button as={Link} to={routes.courses.listing}>
                              Cancel
                            </Form.Button>
                          </div>
                        </>
                      )}
                    />
                  </Form.Container>
                ) : (
                  <Segment placeholder className="placeholder-container">
                    {categoryOptions?.length ? (
                      <>
                        <Header icon>
                          <FontAwesomeIcon icon={faInfoCircle} />
                          There are no questions for the selected category
                        </Header>
                        <Button
                          content="Add Question"
                          icon="add"
                          primary
                          onClick={addNewQuestion}
                        />
                      </>
                    ) : (
                      <>
                        <Header icon>
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          <br />
                          No categories were found for this course.
                          <br />
                          Create a category to begin adding questions.
                        </Header>
                        <Button
                          as={Link}
                          to={buildPath(
                            routes.courses.detail.categories.create,
                            {
                              courseId,
                            }
                          )}
                          primary
                        >
                          <FontAwesomeIcon icon={faLayerGroup} /> Add Category
                        </Button>
                      </>
                    )}
                  </Segment>
                )}
              </div>
            )}
          </AsyncStateContainer>
        </div>
      )}
    </AsyncStateContainer>
  );
};

export default CourseAssessmentEditor;

const styles = css`
  .category {
    margin-bottom: 1em;

    span {
      margin-right: 0.8em;
    }
  }
  .ui.segment.questions-container {
    padding: 1.5em 2em;
  }

  .ui.segment.placeholder {
    &.placeholder-container {
      padding: 20px;

      .ui.icon.header {
        padding-top: 2px;
        padding-bottom: 10px;
      }
    }
  }

  .form-questions {
    max-height: 510px;
    overflow: auto;
    padding-right: 10px;
  }

  .ui.form .form-actions {
    margin-top: 0px;
    padding-top: 20px;
  }

  .actions-container {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .search-input {
      max-width: 360px;
      flex: 1;
      margin-right: 5px;
    }
  }
`;
