import currency from 'currency.js';

export const parseCurrency = (input: string) => {
  const parsedValue = currency(input).intValue;
  return isNaN(parsedValue) ? 0 : parsedValue;
};

export const formatCurrency = (value: any) => {
  const raw = currency(value);
  return isNaN(raw.value) ? '0.00' : raw.toString();
};
