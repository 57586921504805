import React from 'react';
import {css} from '@emotion/core';
import {cx} from 'emotion';

export const Flex = () => <div>Namespace Only. Do Not Use</div>;

type FlexProps = {
  className?: string;
  fill?: boolean;
};

type RowProps = FlexProps & {
  align?: 'top' | 'center' | 'bottom';
  vAlign?: 'top' | 'center' | 'bottom';
};

type ColProps = FlexProps & {
  align?: 'left' | 'center' | 'right';
};

const Row: React.FC<RowProps> = (props) => {
  const className = cx('row', props.className, {
    [props.align as string]: !!props.align,
    [props.vAlign as string]: !!props.vAlign,
    fill: !!props.fill,
  });
  const {children} = props;

  return (
    <div css={styles} className={className}>
      {children}
    </div>
  );
};

const Col: React.FC<ColProps> = (props) => {
  const className = cx('column', props.className, {
    [props.align as string]: !!props.align,
    fill: !!props.fill,
  });
  const {children} = props;

  return (
    <div css={styles} className={className}>
      {children}
    </div>
  );
};

const Fill: React.FC<{className?: string}> = ({children, className}) => (
  <div className={cx('fill', className)}>{children}</div>
);
const Box: React.FC<{className?: string}> = ({children, className}) => (
  <div className={cx('box', className)}>{children}</div>
);

Flex.Row = Row;
Flex.Col = Col;
Flex.Fill = Fill;
Flex.Box = Box;

const styles = css`
  &.row {
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: 767px) {
      display: block;

      > * {
        margin-bottom: 1rem;
      }
    }

    &.top {
      align-items: flex-start;
    }

    &.center {
      align-items: center;
    }

    &.bottom {
      align-items: flex-end;
    }
  }

  &.column {
    display: flex;
    flex-direction: column;

    &.center {
      align-items: center;
    }

    &.bottom {
      align-items: flex-end;
    }
  }

  .fill {
    flex: 1 1 0%;
  }

  .box {
    display: flex;
    align-items: center;
  }
`;
