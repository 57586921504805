import React from 'react';
import css from '@emotion/css/macro';
import {NavLink} from 'react-router-dom';
import {Menu} from 'semantic-ui-react';
import {routes} from '../../../routes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faInfoCircle,
  faSchool,
} from '@fortawesome/pro-regular-svg-icons';
import {buildPath} from '../../../routes/utils';
import {themeColors} from '../../../styles';
import {faLayerGroup} from '@fortawesome/pro-solid-svg-icons';

export const CourseDetailNavigation = ({courseId}) => (
  <Menu pointing secondary className="course-navigation-menu" css={styles}>
    <Menu.Item
      as={NavLink}
      exact
      to={buildPath(routes.courses.detailBase, {id: courseId})}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
      <span className="link-text">Details</span>
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={buildPath(routes.courses.detail.content, {id: courseId})}
    >
      <FontAwesomeIcon icon={faSchool} />
      <span className="link-text">Content</span>
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={buildPath(routes.courses.detail.assessment, {id: courseId})}
    >
      <FontAwesomeIcon icon={faFileAlt} />
      <span className="link-text">Assessment</span>
    </Menu.Item>
    <Menu.Item
      as={NavLink}
      to={buildPath(routes.courses.detail.categories.listing, {id: courseId})}
    >
      <FontAwesomeIcon icon={faLayerGroup} />
      <span className="link-text">Manage Categories</span>
    </Menu.Item>
  </Menu>
);

export default CourseDetailNavigation;

const styles = css`
  &.ui.secondary.pointing.menu {
    &.course-navigation-menu {
      border-bottom: 2px solid ${themeColors.blue100};

      a.item {
        &.active:hover {
          border-bottom: 2px solid ${themeColors.blue400};
        }

        &:not(.active):hover {
          border-bottom: 2px solid ${themeColors.gray300};
        }

        min-width: 96px;
        padding: 10px 14px;

        .link-text {
          padding-left: 10px;
        }
      }
    }
  }
`;
