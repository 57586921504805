import React, {Suspense, useMemo} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {CoursesService} from '../../../api/generated';
import {BasicPage} from '../../../basic-page';
import {routes} from '../../../routes';
import {CourseDetailRoutes} from './course-detail-routes';
import CourseDetailNavigation from './course-detail-navigation';
import {CourseDetailsProvider} from './course-details-context';

export const CourseDetails = () => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id});

    return data;
  }, [id]);

  const courseName = fetchCourseSummary.value?.name ?? '';
  const title = useMemo(
    () => [
      {title: 'Return to Courses', url: routes.courses.listing},
      {title: courseName},
    ],
    [courseName]
  );

  return (
    <CourseDetailsProvider>
      <BasicPage title={title}>
        <CourseDetailNavigation courseId={id} />
        <Suspense fallback={null}>
          <CourseDetailRoutes />
        </Suspense>
      </BasicPage>
    </CourseDetailsProvider>
  );
};
