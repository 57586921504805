import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from './index';
import UserLanding from '../users/landing';
import CourseContentViewer from '../enrollments/course-content/course-content-viewer';
import CourseContentStart from '../enrollments/course-content/course-content-start';
import AssessmentAttemptFinish from '../enrollments/assessment-attempts/assessment-attempt-finish';
import CourseEnrollmentDetails from '../enrollments/course-enrollment-details';
import AssessmentAttemptScreen from '../enrollments/assessment-attempts/assessment-attempt-screen';
import EnrollmentResults from '../enrollments/enrollment-results';

const OrganizationRoutes = () => {
  return (
    <Switch>
      <Route path={routes.organization.landing} exact>
        <UserLanding />
      </Route>
      <Route path={routes.organization.course.details} exact>
        <CourseEnrollmentDetails />
      </Route>
      <Route path={routes.organization.course.content} exact>
        <CourseContentViewer />
      </Route>
      <Route path={routes.organization.course.contentStart} exact>
        <CourseContentStart />
      </Route>
      <Route path={routes.organization.course.assessment} exact>
        <AssessmentAttemptScreen />
      </Route>
      <Route path={routes.organization.course.assessmentSubmit} exact>
        <AssessmentAttemptFinish />
      </Route>
      <Route path={routes.organization.course.enrollmentResults} exact>
        <EnrollmentResults />
      </Route>
    </Switch>
  );
};

export default OrganizationRoutes;
