import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'typeface-roboto';
import 'semantic-ui-less/semantic.less';
import '@reach/skip-nav/styles.css';
import '@reach/tooltip/styles.css';
import 'react-quill/dist/quill.snow.css';

import './dev-tools';
import './analytics/sentry';
import './api/generated/config';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {App} from './app';
import {AuthProvider} from './auth/use-auth';
import {Env} from './config/env-vars';
import {GlobalStyles} from './styles';
import {isIE} from 'react-device-detect';
import {SupportedBrowsersMessage} from './components/supported-browsers-message';

ReactDOM.render(
  <Router basename={Env.subdirectory}>
    {isIE ? (
      <SupportedBrowsersMessage />
    ) : (
      <AuthProvider>
        <GlobalStyles />
        <App />
      </AuthProvider>
    )}
  </Router>,
  document.getElementById('root')
);
