import React from 'react';
import styled from '@emotion/styled/macro';
import {animated} from 'react-spring';

const defaultProps = {
  scroll: false,
};

type Props = {
  open: boolean;
  children: React.ReactNode;
  getKey: () => string;
  transitions: any;
} & StyleProps;

type StyleProps = {
  scroll: boolean;
  height: number;
};

const MobileSlideOutStyles = styled.div<StyleProps>`
  .slide-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: ${(props) => `${props.height}px`};
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    width: 100%;
    z-index: 1;
    border-left: solid 1px #c3c3c3;
    display: flex;
    flex-direction: column;
    border-top: 5px;

    overflow: ${(props) => (props.scroll ? 'auto' : 'hidden')};

    .slide-header {
      display: flex;
      align-items: center;
      padding: 10px 1.5rem;
      background: #fff;

      .ui.header {
        margin: 0;
        flex: 1;
      }
    }

    .slide-content {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
    }
  }
`;

export const MobileNavigationSlideOut = ({
  children,
  height,
  scroll,
  transitions,
}: Props) => {
  return (
    <MobileSlideOutStyles scroll={scroll} height={height}>
      {transitions.map(({item, key, props}) => {
        return (
          item && (
            <animated.div key={key} style={props} className="slide-overlay">
              <>{children}</>
            </animated.div>
          )
        );
      })}
    </MobileSlideOutStyles>
  );
};

MobileNavigationSlideOut.defaultProps = defaultProps;

MobileNavigationSlideOut.Header = ({children}) => (
  <div className="slide-header">{children}</div>
);
MobileNavigationSlideOut.Content = ({children}) => (
  <div className="slide-content">{children}</div>
);
