import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Segment} from 'semantic-ui-react';
import {
  CourseSummaryDto,
  CoursesService,
  AssessmentsService,
} from '../../api/generated';
import {useOrganizationSlug, userIsAdmin, useUser} from '../../auth/use-auth';

import {BasicPage} from '../../basic-page';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {notifications} from '../../utils/notification-service';

const AssessmentAttemptFinish = () => {
  const organizationSlug = useOrganizationSlug();
  const history = useHistory();
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
    assessmentAttemptId: string;
  }>();
  const courseId = Number(match.params.courseId);
  const enrollmentId = Number(match.params.enrollmentId);
  const assessmentAttemptId = Number(match.params.assessmentAttemptId);
  const user = useUser();

  const [course, setCourse] = useState<CourseSummaryDto>();

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id: courseId});

    return data;
  }, [courseId]);

  useEffect(() => {
    if (fetchCourseSummary.value) {
      setCourse(fetchCourseSummary.value);
    }
  }, [fetchCourseSummary.value]);

  const courseType = course ? `${course.type}` : undefined;

  const title = useMemo(
    () =>
      userIsAdmin(user)
        ? [
            {
              title: 'Return to Main Screen',
              url: routes.organization.landing,
            },
            {title: course?.name ?? ''},
          ]
        : [{title: course?.name ?? ''}],
    [course, user]
  );

  const navigateBackToQuestions = () => {
    history.push(
      buildPath(routes.organization.course.assessment, {
        slug: organizationSlug,
        courseId,
        enrollmentId,
        assessmentId: assessmentAttemptId,
      })
    );
  };

  const submitAssessmentForGrading = async () => {
    if (!assessmentAttemptId) {
      return;
    }

    var response = await AssessmentsService.completeAssessmentAttempt({
      assessmentAttemptId,
    });

    if (response.hasErrors) {
      notifications.error(response.errors[0].errorMessage);
    } else {
      history.push(
        buildPath(routes.organization.course.assessmentResults, {
          slug: organizationSlug,
          courseId,
          enrollmentId,
          assessmentAttemptId,
        })
      );
    }
  };

  return (
    <BasicPage title={title} subTitle={courseType}>
      {course && (
        <Segment>
          <p>Would you like to submit your assessment?</p>
          <div className="actions">
            <Button
              primary
              content="Back to Questions"
              onClick={navigateBackToQuestions}
            />
            <Button
              primary
              content="Submit"
              onClick={submitAssessmentForGrading}
            />
          </div>
        </Segment>
      )}
    </BasicPage>
  );
};

export default AssessmentAttemptFinish;
