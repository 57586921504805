import React, {useCallback} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {CategoriesService} from '../../../../api/generated';
import {AsyncStateContainer} from '../../../../components/async-state-container';
import {Form} from '../../../../forms';
import {useNotification} from '../../../../hooks/use-notifications';
import {routes} from '../../../../routes';
import {buildPath} from '../../../../routes/utils';

export const CourseCategoryDetail = () => {
  const match = useRouteMatch<{courseId: string; id: string}>();
  const history = useHistory();
  const courseId = Number(match.params.courseId);
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchCategory = useAsync(async () => {
    const {data} = await CategoriesService.getById({id});
    return data;
  }, [id]);

  const onSubmit = useCallback(
    async (values) => {
      const response = await CategoriesService.update({
        id,
        body: {courseId, ...values},
      });

      if (response.hasErrors) {
        return response;
      }

      notifications.success('Category updated');
      history.push(
        buildPath(routes.courses.detail.categories.listing, {id: courseId})
      );
    },
    [courseId, notifications, history, id]
  );

  return (
    <AsyncStateContainer {...fetchCategory}>
      {fetchCategory.value && (
        <Form.Container>
          <Form
            initialValues={fetchCategory.value}
            onSubmit={onSubmit}
            render={() => (
              <>
                <Form.Row>
                  <Form.Input fieldName="name" fieldLabel="Name" />
                  <Form.Input
                    fieldName="minimumQuestionsCount"
                    fieldLabel="Minimum Questions Count"
                  />
                </Form.Row>

                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Category
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={buildPath(routes.courses.detail.categories.listing, {
                      id: courseId,
                    })}
                  >
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Form.Container>
      )}
    </AsyncStateContainer>
  );
};
