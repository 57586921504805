import css from '@emotion/css/macro';
import {cx} from 'emotion';
import {omit, pick, sum} from 'lodash';
import React from 'react';
import {Field, FieldRenderProps} from 'react-final-form';
import {Form, StrictFormFieldProps} from 'semantic-ui-react';
import {FieldConfig} from './schema-utils';
import {SubmitError} from './submit-error';
import {RequireOnlyOne} from '../types';
import {Media} from '../styles/breakpoints';

export type FieldConfigProps = RequireOnlyOne<
  {
    fieldName?: string;
    fieldConfig?: FieldConfig;
  },
  'fieldName' | 'fieldConfig'
>;

export type FieldPropsStrict = FieldConfigProps & {
  fieldLabel?: string;
  fieldClassName?: string;
  fieldHint?: React.ReactNode;
  fieldWidth?: StrictFormFieldProps['width'];
};

type FieldProps<T> = T & FieldPropsStrict;

type RenderComponentProps<T> = T & FieldRenderProps<any, HTMLElement>;

type RenderComponent<T> = {
  renderComponent: (
    renderComponentProps: RenderComponentProps<T>
  ) => React.ReactNode;
};

const FieldPropKeys = [
  'fieldConfig',
  'fieldName',
  'fieldLabel',
  'fieldClassName',
  'fieldHint',
  'fieldWidth',
  'inputProps',
] as const;

export function FieldFactory<T>({renderComponent}: RenderComponent<T>) {
  return (props: FieldProps<T>) => {
    const fieldProps = pick(props, FieldPropKeys);
    const finalFormFieldProps = omit(props, FieldPropKeys);

    const {
      fieldName,
      fieldLabel,
      fieldClassName,
      fieldHint,
      fieldWidth,
    } = props.fieldConfig ? props.fieldConfig : fieldProps;

    const inputProps = props.fieldConfig?.inputProps ?? {};

    if (!fieldName) {
      throw new Error('fieldName is required');
    }

    return (
      <Field
        id={fieldName}
        name={fieldName as string}
        render={renderComponentProps => {
          const {input, meta} = renderComponentProps;
          return (
            <div className={cx('form-field', fieldClassName)}>
              <Form.Field width={fieldWidth} error={!!meta.submitError}>
                {fieldLabel ? (
                  <label htmlFor={input.name}>{fieldLabel}</label>
                ) : (
                  <span className="no-label" />
                )}
                {renderComponent(
                  renderComponentProps as RenderComponentProps<T>
                )}
              </Form.Field>
              {fieldHint && <div className="form-field-hint">{fieldHint}</div>}
              <SubmitError name={input.name} />
            </div>
          );
        }}
        {...inputProps}
        {...finalFormFieldProps}
      />
    );
  };
}

type FormRow = {
  proportions?: number[];
};

export const FormRow: React.FC<FormRow> = ({proportions, ...props}) => {
  const styles = getStyle(proportions);

  return <div className="form-row" css={styles} {...props} />;
};

const styleCache = {};
const getStyle = (proportions: number[] = []) => {
  const key = `cols-${proportions.join('-')}`;
  if (!styleCache[key]) {
    const denominator = sum(proportions);
    styleCache[key] = css`
      ${Media('TabletMin')} {
        ${(proportions || []).map(
          (x, i) => css`
            .form-field:nth-of-type(${i + 1}) {
              width: ${(x / denominator) * 100}%;
              flex: none !important;
            }
          `
        )};
      }
    `;
  }

  return styleCache[key];
};
