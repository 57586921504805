import {css} from '@emotion/core';
import React from 'react';
import {Radio, Input, Table, Checkbox} from 'semantic-ui-react';
import {themeColors} from '../../../../styles';

const AssessmentQuestion = ({question, updateQuestion}) => {
  const updateQuestionValue = (_, {value}) => {
    updateQuestion({...question, value});
  };

  const updateQuestionIsEnabled = (_, {checked}) => {
    updateQuestion({...question, isEnabled: checked});
  };

  const updateAnswerValue = (index, value) => {
    const updatedQuestion = {
      ...question,
      answers: question.answers.map((answer, idx) =>
        idx === index ? {...answer, value} : answer
      ),
    };

    updateQuestion(updatedQuestion);
  };

  const updateAnswerIsCorrectAnswer = (index) => {
    const updatedQuestion = {
      ...question,
      answers: question.answers.map((answer, idx) =>
        idx === index
          ? {...answer, isCorrectAnswer: true}
          : {...answer, isCorrectAnswer: false}
      ),
    };

    updateQuestion(updatedQuestion);
  };

  return (
    <div className="question-editor-container" css={styles}>
      <div className="question-container">
        <Input
          className="question-input"
          value={question.value}
          onChange={updateQuestionValue}
        />
        <Checkbox
          className="show-question-toggle"
          toggle
          checked={question.isEnabled}
          label={`${question.isEnabled ? 'Shown' : 'Hidden'}`}
          onChange={(e, {checked}) => updateQuestionIsEnabled(e, {checked})}
        />
      </div>
      <div className="answers-container">
        <div className="answers-header">Answers</div>
        <Table basic="very" className="answers-table" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {question.answers.map((answer, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Radio
                    checked={answer.isCorrectAnswer}
                    onChange={() => updateAnswerIsCorrectAnswer(index)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Input
                    className={
                      answer.isCorrectAnswer ? 'correct-answer-input' : ''
                    }
                    fluid
                    value={answer.value}
                    onChange={(_, {value}) => updateAnswerValue(index, value)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default React.memo(AssessmentQuestion);

const styles = css`
  &.question-editor-container {
    margin-bottom: 40px;
    font-size: 0.85em;
  }

  .question-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .question-number {
      font-size: 1.2em;
      padding-right: 20px;
      font-weight: bold;
    }

    .question-input {
      flex: 1;
      font-size: 1.1em;
    }

    .show-question-toggle {
      margin-left: 30px;
      min-width: 115px;
    }
  }

  .answers-container {
    margin-top: 15px;
    margin-left: 38px;
    max-width: 600px;

    .answers-header {
      font-size: 1.2em;
    }

    .correct-answer-input > input {
      background-color: ${themeColors.green100};
      &:focus {
        background-color: ${themeColors.green100};
      }
    }

    .answers-table {
      &.ui.table {
        margin-top: 0px;

        th,
        td {
          border-top: none;
          border-bottom: none;

          &.collapsing {
            min-width: 40px;
          }
        }

        td {
          padding: 1.5px 0px;
        }
      }
    }
  }
`;
