import React, {useEffect, useMemo, useState} from 'react';
import {Header, Segment} from 'semantic-ui-react';
import {useRouteMatch} from 'react-router-dom';
import {CourseSummaryDto, EnrollmentsService} from '../../api/generated';
import {useAsync} from 'react-use';
import {AsyncStateContainer} from '../../components/async-state-container';
import {BasicPage} from '../../basic-page';
import {css} from '@emotion/core';
import {themeColors} from '../../styles';
import ContentRenderer from '../../components/content-renderer';
import {routes} from '../../routes';
import {userIsAdmin, useUser} from '../../auth/use-auth';

const CourseContentViewer = () => {
  const [courseInformation, setCourseInformation] = useState<
    CourseSummaryDto
  >();
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
    slug: string;
  }>();
  const enrollmentId = Number(match.params.enrollmentId);

  const [courseReset, setCourseReset] = useState<number>(+new Date());
  const user = useUser();

  const refetchEnrollment = () => {
    setCourseReset(+new Date());
  };
  const fetchCourse = useAsync(async () => {
    const {data} = await EnrollmentsService.getCourseByEnrollmentId({
      id: enrollmentId,
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentId, courseReset]);

  useEffect(() => {
    if (fetchCourse?.value) {
      setCourseInformation(fetchCourse.value);
    }
  }, [fetchCourse]);

  const courseName = courseInformation?.name ?? '';
  const title = useMemo(
    () =>
      userIsAdmin(user)
        ? [
            {
              title: 'Return to Dashboard',
              url: routes.organization.landing,
            },
            {title: courseName},
          ]
        : [{title: courseName}],
    [courseName, user]
  );

  return (
    <AsyncStateContainer {...fetchCourse}>
      <div css={styles}>
        <div className="course-content-container">
          <BasicPage
            title={title}
            subTitle={courseInformation ? `${courseInformation?.type}` : ''}
          >
            {!fetchCourse.loading && fetchCourse.value ? (
              <ContentRenderer
                content={fetchCourse.value}
                refetchEnrollment={refetchEnrollment}
              />
            ) : (
              <Segment placeholder>
                <Header content="This course does not have any study content" />
              </Segment>
            )}
          </BasicPage>
        </div>
      </div>
    </AsyncStateContainer>
  );
};

export default CourseContentViewer;

const styles = css`
  height: 100%;

  .study-guide {
    font-weight: bold;
    font-size: 26px;
    padding: 32px 25px;
    color: ${themeColors.blue700};
    max-width: 1380px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .course-content-container {
    height: 100%;
    background-color: ${themeColors.gray50};
  }
`;
