/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, {createContext, useState} from 'react';
import {CourseGetDto} from '../../../api/generated';

import {CourseContentSnapshot} from './types';

export const CourseDetailsContext = createContext<CourseContentSnapshot>({
  contentSnapshot: undefined,
  lastPageNumber: 1,
  updateContentSnapshot: () => {},
});

export const CourseDetailsProvider = ({children}) => {
  const [contentSnapshot, setContentSnapshot] = useState<CourseGetDto>();

  const [lastPageNumber] = useState<number>(1);

  const updateContentSnapshot = (contentValue) => {
    setContentSnapshot(contentValue);
  };

  const value = {
    contentSnapshot,
    lastPageNumber,
    updateContentSnapshot,
  };

  return (
    <CourseDetailsContext.Provider value={value}>
      {children}
    </CourseDetailsContext.Provider>
  );
};
