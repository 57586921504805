import {faPlus, faUnlock} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useAsyncFn} from 'react-use';
import {Button, Dropdown} from 'semantic-ui-react';
import {UsersService} from '../../api/generated';
import {AllPermissions, Roles} from '../../api/generated/enums';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {BasicPage} from '../../basic-page';
import {ConfirmButton} from '../../components/confirm-button';
import {Form} from '../../forms';
import {fieldConfig, getDefaults, input} from '../../forms/schema-utils';
import {useProduce} from '../../hooks/use-produce';
import {routes} from '../../routes';
import {notifications} from '../../utils/notification-service';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
  AdditionalParams,
  getActiveFiltersCount,
} from '../../hooks/use-paged-data-table';

type FilterState = AdditionalParams<typeof UsersService.getAll>;
type ComponentState = {
  filter: FilterState;
  tableState?: Parameters<typeof UsersService.getAll>[0];
};

export const UserListing = () => {
  const [state, setState] = useProduce<ComponentState>({
    filter: {
      claims: [],
      roles: [],
    },
  });

  const additionalParams = state.filter;
  const filtersInSlideOut = additionalParams.claims;

  const pagedDataTable = usePagedDataTable(UsersService.getAll, TableConfig, {
    additionalParams: additionalParams,
    onStateChange: useCallback(
      (tableState) => {
        setState((draft) => {
          draft.tableState = tableState;
        });
      },
      [setState]
    ),
    filter: (dismiss) => (
      <TableFilters
        initialValues={state.filter}
        onApply={(filters) => {
          setState((draft) => {
            draft.filter.claims = filters.claims;
          });

          dismiss();
        }}
      />
    ),
    filterBadge: getActiveFiltersCount(filtersInSlideOut),
    actions: (
      <>
        <label>Role:&nbsp;</label>
        <Dropdown
          options={getEnumDropdownOptions(Roles)}
          onChange={(e, {value}) => {
            setState((draft) => {
              if (value) {
                draft.filter.roles = [value.toString()];
              } else {
                draft.filter.roles = [];
              }
            });
          }}
          selection
          clearable
        ></Dropdown>
        &nbsp;
        <Button as={Link} to={routes.users.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New User
        </Button>
      </>
    ),
  });

  return <BasicPage title="Users">{pagedDataTable}</BasicPage>;
};

const fields = fieldConfig<any>({
  claims: input({
    fieldLabel: 'Claims',
  }),
});

const mutators = {
  ...arrayMutators,
};

const TableFilters = ({initialValues, onApply}) => {
  return (
    <Form
      initialValues={initialValues || getDefaults(fields)}
      mutators={mutators}
      onSubmit={(values) => {
        onApply(values);
      }}
      render={() => (
        <>
          <Form.MultiSelectCheckbox
            fieldName={fields.claims.fieldName}
            options={AllPermissions.map((x) => x)}
            fieldLabel={fields.claims.fieldLabel}
          />
          <div className="form-actions">
            <Form.Button type="submit" primary>
              Apply Filter
            </Form.Button>
          </div>
        </>
      )}
    />
  );
};

const ResetPasswordButton = ({id}) => {
  const [sendResetState, sendReset] = useAsyncFn(async () => {
    const response = await UsersService.sendPasswordReset({
      id,
    });

    if (response.hasErrors) {
      notifications.error('Failed to send password reset link');
    } else {
      notifications.success('Password reset link sent!');
    }
  });

  return (
    <ConfirmButton
      onConfirm={sendReset}
      loading={sendResetState.loading}
      icon={faUnlock}
      loadingText="Sending..."
      tooltip="Send Password Reset"
    ></ConfirmButton>
  );
};

const TableConfig = PagedDataTableConfig(UsersService.getAll, {
  columns: [
    {
      header: '',
      render: (item) => (
        <>
          {renderEditButton({
            item,
            descriptor: 'User Account',
            route: routes.users.detail,
          })}
          <ResetPasswordButton id={item.id} />
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Email Address',
      column: 'emailAddress',
      sortable: 'emailAddress',
    },
    {
      header: 'Name',
      render: (item) => `${item.firstName} ${item.lastName}`,
      sortable: 'firstName',
    },
    {
      header: 'Role',
      column: 'role',
    },
  ],
  defaultSort: {
    column: 'emailAddress',
    direction: 'ASC',
  },
  searchFieldNames: ['firstName', 'lastName', 'emailAddress'],
});
