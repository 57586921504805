/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
export interface Response<T> {
  data: T | null;
  hasErrors: boolean;
  errors: Error[];
}

export interface Error {
  propertyName: string;
  errorMessage: string;
}

export interface AdvancedQueryPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export class AssessmentsService {
  /**
   *
   */
  static getCategoryQuestionsByCourseId(
    params: {
      /**  */
      courseId: number;
      /**  */
      categoryId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<QuestionGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses/{courseId}/categories/{categoryId}/assessment';
      url = url.replace('{courseId}', params['courseId'] + '');
      url = url.replace('{categoryId}', params['categoryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createOrUpdateQuestionsByCategoryIdRequest(
    params: {
      /**  */
      courseId: number;
      /**  */
      categoryId: number;
      /**  */
      body?: CreateOrUpdateQuestionsByCategoryIdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses/{courseId}/categories/{categoryId}/assessment';
      url = url.replace('{courseId}', params['courseId'] + '');
      url = url.replace('{categoryId}', params['categoryId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAssessmentAttemptResults(
    params: {
      /**  */
      assessmentTakenOnOrAfterDate?: string;
      /**  */
      assessmentTakenOnOrBeforeDate?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AssessmentAttemptResultDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/assessment-attempts/results';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AssessmentTakenOnOrAfterDate: params['assessmentTakenOnOrAfterDate'],
        AssessmentTakenOnOrBeforeDate: params['assessmentTakenOnOrBeforeDate'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAssessmentAttemptQuestionResponse(
    params: {
      /**  */
      assessmentAttemptId: number;
      /**  */
      questionResponseId: number;
      /**  */
      body?: UpdateAssessmentAttemptQuestionResponseRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/assessment-attempts/{assessmentAttemptId}/questions/{questionResponseId}';
      url = url.replace('{assessmentAttemptId}', params['assessmentAttemptId'] + '');
      url = url.replace('{questionResponseId}', params['questionResponseId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static completeAssessmentAttempt(
    params: {
      /**  */
      assessmentAttemptId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/assessment-attempts/{assessmentAttemptId}/complete';
      url = url.replace('{assessmentAttemptId}', params['assessmentAttemptId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beginChapterAssessmentAttempt(
    params: {
      /**  */
      id: number;
      /**  */
      chapterId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AssessmentAttemptDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments/{id}/begin-chapter-assessment/{chapterId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{chapterId}', params['chapterId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beginCourseAssessmentAttempt(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AssessmentAttemptDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments/{id}/begin-course-assessment';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAssessmentAttemptById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AssessmentAttemptDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/assessment-attempts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CategoriesService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateCategoryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CategoryGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      courseId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<CategorySummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CourseId: params['courseId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CategoryGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateCategoryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CategoryGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/categories/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ChaptersService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateChapterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ChapterGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chapters';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chapters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ChapterGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chapters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateChapterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ChapterGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chapters/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reorder(
    params: {
      /**  */
      body?: ReorderChaptersRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chapters/reorder';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompileTimeStaticsService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/compile-time-statics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CoursesService {
  /**
   *
   */
  static getAllCourses(
    params: {
      /**  */
      courseType?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<CourseSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CourseType: params['courseType'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseSummaryById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CourseSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses/{id}/summary';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseContentSummaryByCourseId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CourseContentSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses/{id}/content-summary';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editCourseDetailsById(
    params: {
      /**  */
      id: number;
      /**  */
      body?: EditCourseDetailsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CourseGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/courses/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EnrollmentsService {
  /**
   *
   */
  static getEnrollmentsSummaryByUserId(
    params: {
      /**  */
      request?: any | null;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<EnrollmentSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { request: params['request'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseByEnrollmentId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CourseGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments/{id}/course';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseAttemptProgressByEnrollmentId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CourseAttemptProgressDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments/{id}/progress';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEnrollmentByCourseId(
    params: {
      /**  */
      courseId: number;
      /**  */
      body?: CreateEnrollmentByCourseIdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<EnrollmentSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/enrollments/api/courses/{courseId}/enroll';
      url = url.replace('{courseId}', params['courseId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateContentStatus(
    params: {
      /**  */
      id: number;
      /**  */
      body?: EditContentStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}/content-status';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteEnrollmentById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static restartCourseByEnrollmentId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}/restart-course';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCourseAttemptProgressByEnrollmentId(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateCourseAttemptProgressRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}/progress';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static restartChapterByEnrollmentId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}/restart-chapter';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCompletedEnrollmentSummary(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CompletedEnrollmentSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/{id}/completed-summary';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   *
   */
  static upload(
    params: {
      /**  */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UploadFileResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null as any;
      data = new FormData();
      if (params['file']) {
        data.append('file', params['file'] as any);
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationsService {
  /**
   *
   */
  static getCourseCatalog(
    params: {
      /**  */
      courseType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<GetAllCoursesFilteredForIntegrationsResponseDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/courses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { courseType: params['courseType'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEnrollmentDetails(
    params: {
      /**  */
      enrollmentIdentifier?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<EnrollmentIntegrationDetailsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/enrollments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { enrollmentIdentifier: params['enrollmentIdentifier'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createEnrollment(
    params: {
      /**  */
      body?: CreateIntegrationsEnrollmentRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CreateIntegrationsEnrollmentResponseDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/enrollments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLoginUrlForEnrollment(
    params: {
      /**  */
      enrollmentIdentifier?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<EnrollmentIntegrationLoginUrlDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/login';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { enrollmentIdentifier: params['enrollmentIdentifier'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createWebhookSubscription(
    params: {
      /**  */
      body?: CreateWebhookSubscriptionRequestDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CreateWebhookSubscriptionRequestResponseDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/integrations/webhooks/set';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PagesService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreatePageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PageGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pages';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PageGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdatePageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PageGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pages/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reorder(
    params: {
      /**  */
      body?: ReorderPagesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pages/reorder';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SectionsService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateSectionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SectionGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sections';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sections/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SectionGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sections/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateSectionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SectionGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sections/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reorder(
    params: {
      /**  */
      body?: ReorderSectionsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sections/reorder';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SettingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateSettingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      claims?: string[];
      /**  */
      roles?: string[];
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<UserSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Claims: params['claims'],
        Roles: params['roles'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateUserPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/password-update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPasswordReset(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/send-reset';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface Error {
  /**  */
  propertyName: string;

  /**  */
  errorMessage: string;
}

export interface AnswerGetDto {
  /**  */
  id: number;

  /**  */
  value: string;

  /**  */
  questionId: number;

  /**  */
  isCorrectAnswer: boolean;
}

export interface QuestionGetDto {
  /**  */
  id: number;

  /**  */
  value: string;

  /**  */
  isEnabled: boolean;

  /**  */
  categoryId: number;

  /**  */
  answers: AnswerGetDto[];
}

export interface AnswerDto {
  /**  */
  value: string;

  /**  */
  isCorrectAnswer: boolean;
}

export interface QuestionDto {
  /**  */
  id: number;

  /**  */
  value: string;

  /**  */
  isEnabled: boolean;

  /**  */
  courseTemplateId: number;

  /**  */
  categoryId: number;

  /**  */
  answers: AnswerDto[];
}

export interface CreateOrUpdateQuestionsByCategoryIdRequest {
  /**  */
  questions: QuestionDto[];
}

export interface EmptyResponse {
  /**  */
  hasErrors: boolean;

  /**  */
  errors: Error[];
}

export interface AdvancedQueryColumnFilter {
  /**  */
  fieldName: string;

  /**  */
  fieldValue: string;

  /**  */
  exactMatch: boolean;
}

export interface AdvancedQueryColumnSort {
  /**  */
  fieldName: string;

  /**  */
  descending: boolean;
}

export interface AssessmentAttemptResultDto {
  /**  */
  id: number;

  /**  */
  dateTaken: Date;

  /**  */
  totalQuestionsCount: number;

  /**  */
  questionsAnsweredCorrectlyCount: number;

  /**  */
  percentCorrect: number;

  /**  */
  passed: boolean;

  /**  */
  assessmentPassPercentage: number;

  /**  */
  userFirstName: string;

  /**  */
  userLastName: string;

  /**  */
  userLdwfNumber: string;

  /**  */
  courseName: string;

  /**  */
  chapterId: number;

  /**  */
  type: string;

  /**  */
  isFinalized: boolean;
}

export interface UpdateAssessmentAttemptQuestionResponseRequest {
  /**  */
  answerId: number;
}

export interface QuestionResponseAnswerDto {
  /**  */
  id: number;

  /**  */
  answerText: string;
}

export interface QuestionResponseDto {
  /**  */
  id: number;

  /**  */
  questionText: string;

  /**  */
  optionA: QuestionResponseAnswerDto;

  /**  */
  optionB: QuestionResponseAnswerDto;

  /**  */
  optionC: QuestionResponseAnswerDto;

  /**  */
  optionD: QuestionResponseAnswerDto;

  /**  */
  selectedAnswerId?: number;
}

export interface AssessmentAttemptDto {
  /**  */
  assessmentAttemptId: number;

  /**  */
  questionResponses: QuestionResponseDto[];
}

export interface CreateCategoryRequest {
  /**  */
  courseId: number;

  /**  */
  name: string;

  /**  */
  minimumQuestionsCount: number;
}

export interface CategoryGetDto {
  /**  */
  courseId: number;

  /**  */
  name: string;

  /**  */
  minimumQuestionsCount: number;

  /**  */
  id: number;
}

export interface CategorySummaryDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  minimumQuestionsCount: number;
}

export interface UpdateCategoryRequest {
  /**  */
  courseId: number;

  /**  */
  name: string;

  /**  */
  minimumQuestionsCount: number;
}

export interface CreateChapterRequest {
  /**  */
  courseId: number;

  /**  */
  categoryId?: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  hasAssessment: boolean;

  /**  */
  creditTimeSeconds: number;

  /**  */
  orderNumber: number;

  /**  */
  assessmentPassPercentage?: number;
}

export interface ChapterGetDto {
  /**  */
  id: number;

  /**  */
  courseId: number;

  /**  */
  categoryId: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  hasAssessment: boolean;

  /**  */
  orderNumber: number;

  /**  */
  creditTimeSeconds: number;

  /**  */
  assessmentPassPercentage: number;
}

export interface UpdateChapterRequest {
  /**  */
  courseId: number;

  /**  */
  categoryId: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  hasAssessment: boolean;

  /**  */
  creditTimeSeconds: number;

  /**  */
  orderNumber: number;

  /**  */
  assessmentPassPercentage: number;
}

export interface ReorderChaptersRequest {
  /**  */
  courseId: number;

  /**  */
  oldIndex: number;

  /**  */
  newIndex: number;
}

export interface CourseSummaryDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  type: string;

  /**  */
  courseTerms: string;

  /**  */
  chapterAssessmentTerms: string;

  /**  */
  courseAssessmentTerms: string;

  /**  */
  priceInCents: number;

  /**  */
  finalAssessmentPassPercentage: number;

  /**  */
  maxNumberOfAttempts: number;
}

export interface PageSummaryDto {
  /**  */
  id: number;

  /**  */
  isDeleted: boolean;

  /**  */
  title: string;

  /**  */
  orderNumber: number;
}

export interface SectionSummaryDto {
  /**  */
  id: number;

  /**  */
  isDeleted: boolean;

  /**  */
  categoryId: number;

  /**  */
  title: string;

  /**  */
  orderNumber: number;

  /**  */
  category: CategorySummaryDto;

  /**  */
  pages: PageSummaryDto[];
}

export interface ChapterSummaryDto {
  /**  */
  id: number;

  /**  */
  categoryId: number;

  /**  */
  isDeleted: boolean;

  /**  */
  title: string;

  /**  */
  courseId: number;

  /**  */
  hasAssessment: boolean;

  /**  */
  orderNumber: number;

  /**  */
  sections: SectionSummaryDto[];
}

export interface CourseContentSummaryDto {
  /**  */
  id: number;

  /**  */
  chapters: ChapterSummaryDto[];
}

export interface PageGetDto {
  /**  */
  id: number;

  /**  */
  contentType: string;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  creditTimeSeconds: number;

  /**  */
  sectionId: number;
}

export interface SectionDetailGetDto {
  /**  */
  id: number;

  /**  */
  contentType: string;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  categoryId: number;

  /**  */
  chapterId: number;

  /**  */
  creditTimeSeconds: number;

  /**  */
  pages: PageGetDto[];
}

export interface ChapterDetailGetDto {
  /**  */
  id: number;

  /**  */
  contentType: string;

  /**  */
  courseId: number;

  /**  */
  categoryId: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  hasAssessment: boolean;

  /**  */
  orderNumber: number;

  /**  */
  creditTimeSeconds: number;

  /**  */
  assessmentPassPercentage: number;

  /**  */
  sections: SectionDetailGetDto[];
}

export interface IContentItemGetDto {
  /**  */
  title: string;

  /**  */
  contentType: string;

  /**  */
  id: number;
}

export interface EditCourseDetailsRequest {
  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  type: string;

  /**  */
  courseTerms: string;

  /**  */
  chapterAssessmentTerms: string;

  /**  */
  courseAssessmentTerms: string;

  /**  */
  priceInCents: number;

  /**  */
  finalAssessmentPassPercentage: number;

  /**  */
  maxNumberOfAttempts: number;

  /**  */
  courseCode: string;

  /**  */
  chapters: ChapterDetailGetDto[];

  /**  */
  flattenedContent: IContentItemGetDto[];
}

export interface CourseGetDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  type: string;

  /**  */
  courseTerms: string;

  /**  */
  chapterAssessmentTerms: string;

  /**  */
  courseAssessmentTerms: string;

  /**  */
  priceInCents: number;

  /**  */
  finalAssessmentPassPercentage: number;

  /**  */
  maxNumberOfAttempts: number;

  /**  */
  courseCode: string;

  /**  */
  chapters: ChapterDetailGetDto[];

  /**  */
  flattenedContent: IContentItemGetDto[];
}

export interface GetEnrollmentsSummaryByUserIdRequest {}

export interface EnrollmentSummaryDto {
  /**  */
  id: number;

  /**  */
  courseId: number;

  /**  */
  isCompleted: boolean;

  /**  */
  inProgressAssessmentAttemptId: number;

  /**  */
  currentPageId: number;
}

export interface CourseAttemptProgressDto {
  /**  */
  inProgressAssessmentAttemptId: number;

  /**  */
  currentPageId: number;

  /**  */
  currentPageType: string;

  /**  */
  contentStatus: string;

  /**  */
  attemptsRemaining: number;

  /**  */
  assessmentAttempts: AssessmentAttemptResultDto[];
}

export interface CreateEnrollmentByCourseIdRequest {}

export interface EditContentStatusRequest {
  /**  */
  enrollmentId: number;

  /**  */
  contentStatus: string;
}

export interface UpdateCourseAttemptProgressRequest {
  /**  */
  enrollmentId: number;

  /**  */
  inProgressAssessmentId?: number;

  /**  */
  currentPageId: number;

  /**  */
  currentContentContainerType: string;
}

export interface CompletedEnrollmentSummaryDto {
  /**  */
  id: number;

  /**  */
  numberOfCourseAttempts: number;

  /**  */
  courseCompletionRedirectUrl: string;

  /**  */
  assessmentAttemptResults: AssessmentAttemptResultDto[];
}

export interface UploadFileResponse {
  /**  */
  uri: string;
}

export interface CourseIntegrationsDto {
  /**  */
  courseId: number;

  /**  */
  courseType: string;

  /**  */
  courseCode: string;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  maxAttempts: number;

  /**  */
  courseTerms: string;
}

export interface GetAllCoursesFilteredForIntegrationsResponseDto {
  /**  */
  courses: CourseIntegrationsDto[];
}

export interface EnrollmentIntegrationDetailsDto {
  /**  */
  enrollmentId: number;

  /**  */
  enrollmentIdentifier: string;

  /**  */
  courseType: string;

  /**  */
  status: string;
}

export interface ParticipantDto {
  /**  */
  firstName: string;

  /**  */
  lastName: string;
}

export interface CreateIntegrationsEnrollmentRequestDto {
  /**  */
  enrollmentIdentifier: string;

  /**  */
  courseId: number;

  /**  */
  participant: ParticipantDto;

  /**  */
  courseCompletionRedirectUrl: string;
}

export interface CreateIntegrationsEnrollmentResponseDto {
  /**  */
  enrollmentId: number;

  /**  */
  loginUrl: string;

  /**  */
  loginUrlExpires: Date;
}

export interface EnrollmentIntegrationLoginUrlDto {
  /**  */
  enrollmentId: number;

  /**  */
  loginUrl: string;

  /**  */
  loginUrlExpires: Date;
}

export interface CreateWebhookSubscriptionRequestDto {
  /**  */
  callbackUrl: string;

  /**  */
  subscriptions: string[];
}

export interface CreateWebhookSubscriptionRequestResponseDto {
  /**  */
  secret: string;
}

export interface CreatePageRequest {
  /**  */
  sectionId: number;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  creditTimeSeconds: number;
}

export interface UpdatePageRequest {
  /**  */
  sectionId: number;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  creditTimeSeconds: number;
}

export interface ReorderPagesRequest {
  /**  */
  sectionId: number;

  /**  */
  oldIndex: number;

  /**  */
  newIndex: number;
}

export interface CreateSectionRequest {
  /**  */
  chapterId: number;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  creditTimeSeconds: number;

  /**  */
  categoryId?: number;
}

export interface SectionGetDto {
  /**  */
  id: number;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  categoryId: number;

  /**  */
  chapterId: number;

  /**  */
  creditTimeSeconds: number;
}

export interface UpdateSectionRequest {
  /**  */
  chapterId: number;

  /**  */
  orderNumber: number;

  /**  */
  title: string;

  /**  */
  value: string;

  /**  */
  creditTimeSeconds: number;

  /**  */
  categoryId: number;
}

export interface ReorderSectionsRequest {
  /**  */
  chapterId: number;

  /**  */
  oldIndex: number;

  /**  */
  newIndex: number;
}

export interface SettingSummaryDto {
  /**  */
  value: string;

  /**  */
  id: number;

  /**  */
  key: string;

  /**  */
  type: string;

  /**  */
  enum: string;
}

export interface UpdateSettingRequest {
  /**  */
  value: string;
}

export interface CreateUserRequest {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  ldwfNumber: string;

  /**  */
  role: string;

  /**  */
  claims: string[];
}

export interface UserGetDto {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  ldwfNumber: string;

  /**  */
  role: string;

  /**  */
  id: number;
}

export interface UserSummaryDto {
  /**  */
  id: number;

  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;
}

export interface UserDetailDto {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  ldwfNumber: string;

  /**  */
  role: string;

  /**  */
  id: number;

  /**  */
  claims: string[];
}

export interface UpdateUserRequest {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  ldwfNumber: string;

  /**  */
  role: string;

  /**  */
  claims: string[];
}

export interface UpdateUserPasswordRequest {
  /**  */
  confirmNewPassword: string;

  /**  */
  currentPassword: string;

  /**  */
  newPassword: string;
}

export interface ExampleType {
  /**  */
  someProperty: string;
}
