import {css} from '@emotion/core';
import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Header, Segment} from 'semantic-ui-react';
import {
  CoursesService,
  CourseSummaryDto,
  EnrollmentsService,
} from '../api/generated';
import {RequireAuthorization} from '../auth/require-authorization';
import {useOrganizationSlug} from '../auth/use-auth';

import {BasicPage} from '../basic-page';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';
import {notifications} from '../utils/notification-service';
import {getResponseErrors} from '../utils/object-helpers';

const CourseEnrollmentDetails = () => {
  const organizationSlug = useOrganizationSlug();
  const history = useHistory();
  const match = useRouteMatch<{courseId: string; enrollmentId: string}>();
  const courseId = Number(match.params.courseId);

  const [course, setCourse] = useState<CourseSummaryDto>();

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id: courseId});

    return data;
  }, [courseId]);

  useEffect(() => {
    if (fetchCourseSummary.value) {
      setCourse(fetchCourseSummary.value);
    }
  }, [fetchCourseSummary.value]);

  const enrollInCourse = async () => {
    const result = await EnrollmentsService.createEnrollmentByCourseId({
      courseId,
      body: {
        courseId,
      },
    });

    if (result.hasErrors) {
      notifications.error(getResponseErrors(result));
    } else {
      const pathToCourseContent = buildPath(
        routes.organization.course.contentStart,
        {
          slug: organizationSlug,
          courseId,
          enrollmentId: Number(result.data?.id),
        }
      );

      history.push(pathToCourseContent);
    }
  };

  const navigateToDashboard = () => {
    history.push(routes.organization.landing);
  };

  return (
    <BasicPage
      css={styles}
      title="Course Details"
      subTitle="Course Information"
    >
      {course && (
        <Segment>
          <Header as="h3" content={`${course.type} Education Course`} />
          <Header as="h3" content="Course Description" />
          <p>
            {course.description || 'This course does not have a description.'}
          </p>
          <Header as="h3" content="Course Terms" />
          <p>{course.courseTerms || 'This course does not have terms.'}</p>
          <Header as="h3" content="Course Cost" />
          <p>${(course.priceInCents / 100).toFixed(2)}</p>
          <div className="actions">
            <Button content="Cancel" onClick={navigateToDashboard} />
            <RequireAuthorization permission="Enrollments: Create">
              <Button primary content="Purchase" onClick={enrollInCourse} />
            </RequireAuthorization>
          </div>
        </Segment>
      )}
    </BasicPage>
  );
};

const styles = css`
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`;

export default CourseEnrollmentDetails;
