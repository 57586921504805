import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Button, CheckboxProps, Form, Header, Segment} from 'semantic-ui-react';
import {
  CoursesService,
  CourseSummaryDto,
  AssessmentsService,
} from '../../api/generated';
import {routes} from '../../routes';
import {useAsync} from 'react-use';
import {AsyncStateContainer} from '../../components/async-state-container';
import {BasicPage} from '../../basic-page';
import {css} from '@emotion/core';
import {
  assessmentAttemptReducer,
  initialReducerState,
} from './assessmentAttemptReducer';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {buildPath} from '../../routes/utils';
import {useOrganizationSlug, userIsAdmin, useUser} from '../../auth/use-auth';
import AssessmentAttemptInformation from './assessment-information';
import {notifications} from '../../utils/notification-service';
import {Media} from '../../styles/breakpoints';

const AssessmentAttemptScreen = () => {
  const [courseInformation, setCourseInformation] = useState<
    CourseSummaryDto
  >();
  const organizationSlug = useOrganizationSlug();
  const history = useHistory();
  const match = useRouteMatch<{
    courseId: string;
    enrollmentId: string;
    assessmentId: string;
  }>();
  const courseId = Number(match.params.courseId);
  const enrollmentId = Number(match.params.enrollmentId);
  const assessmentId = Number(match.params.assessmentId);
  const user = useUser();

  const [
    {assessmentAttempt, currentQuestion, currentQuestionNumber},
    dispatch,
  ] = useReducer(assessmentAttemptReducer, initialReducerState);

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id: courseId});

    return data;
  }, [courseId]);

  useEffect(() => {
    if (fetchCourseSummary.value) {
      setCourseInformation(fetchCourseSummary.value);
    }
  }, [fetchCourseSummary]);

  const fetchAssessmentAttempt = useAsync(async () => {
    if (!assessmentId) {
      return;
    }
    const response = await AssessmentsService.getAssessmentAttemptById({
      id: assessmentId,
    });
    if (response.hasErrors) {
      notifications.error(response.errors[0].errorMessage);
    }
    return response.data;
  }, [assessmentId]);

  useEffect(() => {
    if (fetchAssessmentAttempt?.value) {
      dispatch({
        type: 'SET_ASSESSMENT_ATTEMPT',
        payload: {assessmentAttempt: fetchAssessmentAttempt?.value},
      });
    }
  }, [fetchAssessmentAttempt, fetchAssessmentAttempt.value]);

  useEffect(() => {
    if (assessmentAttempt && !currentQuestion) {
      dispatch({type: 'SHOW_NEXT_QUESTION'});
    }
  }, [assessmentAttempt, currentQuestion]);

  const showNextQuestion = () => {
    dispatch({type: 'SHOW_NEXT_QUESTION'});
  };

  const showPreviousQuestion = () => {
    dispatch({type: 'SHOW_PREVIOUS_QUESTION'});
  };

  const saveAnswer = async (callback) => {
    if (
      !assessmentAttempt ||
      !currentQuestion ||
      !enrollmentId ||
      !currentQuestion.selectedAnswerId
    ) {
      return callback();
    }

    const response = await AssessmentsService.updateAssessmentAttemptQuestionResponse(
      {
        assessmentAttemptId: assessmentAttempt.assessmentAttemptId,
        questionResponseId: currentQuestion.id,
        body: {
          answerId: currentQuestion.selectedAnswerId,
        },
      }
    );

    if (!response.hasErrors) {
      dispatch({type: 'ON_SELECTED_ANSWER_SAVED'});
      callback();
    }
  };

  const selectedAnswerChanged = (_e, {value}: CheckboxProps) => {
    dispatch({
      type: 'SELECT_ANSWER',
      payload: {selectedAnswerId: Number(value)},
    });
  };

  const courseName = courseInformation?.name ?? '';
  const title = useMemo(
    () =>
      userIsAdmin(user)
        ? [
            {title: 'Return to Main Page', url: routes.organization.landing},
            {title: courseName},
          ]
        : [{title: courseName}],
    [courseName, user]
  );

  const subTitle = useMemo(
    () => (courseInformation ? `${courseInformation?.type}` : ''),
    [courseInformation]
  );

  const submitAssessment = async () => {
    if (!assessmentAttempt?.assessmentAttemptId) {
      return;
    }

    var response = await AssessmentsService.completeAssessmentAttempt({
      assessmentAttemptId: Number(assessmentAttempt?.assessmentAttemptId),
    });

    if (response.hasErrors) {
      notifications.error(response.errors[0].errorMessage);
    } else {
      history.push(
        buildPath(routes.organization.course.content, {
          slug: organizationSlug,
          courseId,
          enrollmentId,
        })
      );
    }
  };

  return (
    <AsyncStateContainer {...fetchAssessmentAttempt}>
      <BasicPage css={styles} title={title} subTitle={subTitle}>
        <AssessmentAttemptInformation
          currentQuestionNumber={currentQuestionNumber}
          totalQuestionCount={assessmentAttempt?.questionResponses.length}
        />
        {assessmentAttempt ? (
          <div className="assessment-container">
            {currentQuestion ? (
              <Segment className="current-question-container">
                <Form>
                  <div className="question">{currentQuestion.questionText}</div>
                  <Form.Group className="answers">
                    {[
                      currentQuestion.optionA,
                      currentQuestion.optionB,
                      currentQuestion.optionC,
                      currentQuestion.optionD,
                    ].map((answer) => {
                      return (
                        <Form.Radio
                          className="answer"
                          key={answer.id}
                          label={answer.answerText}
                          value={answer.id}
                          checked={
                            currentQuestion.selectedAnswerId === answer.id
                          }
                          onChange={selectedAnswerChanged}
                        />
                      );
                    })}
                  </Form.Group>
                </Form>
                <div className="actions">
                  <Button
                    disabled={currentQuestionNumber <= 1}
                    content="Previous Question"
                    onClick={() => saveAnswer(showPreviousQuestion)}
                    primary
                  />
                  {currentQuestionNumber ===
                  assessmentAttempt.questionResponses.length ? (
                    <Button
                      disabled={!currentQuestion.selectedAnswerId}
                      content="Complete Assessment"
                      primary
                      onClick={() => saveAnswer(submitAssessment)}
                    />
                  ) : (
                    <Button
                      disabled={!currentQuestion.selectedAnswerId}
                      content="Next Question"
                      onClick={() => saveAnswer(showNextQuestion)}
                      primary
                    />
                  )}
                </div>
              </Segment>
            ) : (
              <div>No questions found</div>
            )}
          </div>
        ) : (
          <Segment placeholder>
            <Header content="An assessment could not be found" />
          </Segment>
        )}
      </BasicPage>
    </AsyncStateContainer>
  );
};

const styles = css`
  .assessment-container {
    margin: auto;

    margin-top: 20px;
    width: 60%;
    min-width: 300px;

    ${Media('MobileMax')} {
      margin-left: 0;
      margin-right: 0;
      padding-right: 1rem;
      width: 100%;
    }

    .current-question-container {
      .question {
        font-size: 1.3em;
        margin-bottom: 10px;
      }

      .answers {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        .answer {
          padding: 4px 0 4px 0;
          border-bottom: 1px solid #eee;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;

        ${Media('MobileMax')} {
          flex-direction: column;
          .button {
            display: block;
            width: 100%;
            margin-bottom: 8px;
          }
          margin-top: 20px;
        }
      }
    }
  }
`;

export default AssessmentAttemptScreen;
