import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Popup, PopupProps} from 'semantic-ui-react';
import {Tooltip} from './tooltip';
import {Button} from '../forms/button';

type ConfirmButton = Omit<Button, 'type' | 'as'> & {
  onConfirm: () => void;
  loading?: boolean;
  text?: string;
  tooltip?: string;
  confirmPosition?: PopupProps['position'];
  loadingText?: string;
  confirmText?: string;
  icon?: IconDefinition;
  confirmProps?: Omit<Button, 'type' | 'as'>;
};

const EmptyWrapper: React.FC<{}> = (props) => <span {...props} />;

export const ConfirmButton: React.FC<ConfirmButton> = ({
  onConfirm,
  loading,
  text,
  loadingText = 'Loading...',
  confirmText = 'Are you sure?',
  confirmPosition = 'right center',
  icon,
  tooltip = '',
  confirmProps,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  const TooltipWrapper = tooltip ? Tooltip : EmptyWrapper;
  const tooltipProps = tooltip ? {label: tooltip} : ({} as any);

  return (
    <Popup
      open={open}
      onClose={onClose}
      trigger={
        <TooltipWrapper {...tooltipProps}>
          <Button
            className="clear"
            basic
            type="button"
            icon={!!icon}
            disabled={loading}
            {...props}
            onClick={toggleOpen}
          >
            {!!icon && (
              <>
                <FontAwesomeIcon icon={icon} />{' '}
              </>
            )}
            <span>{loading ? loadingText : text}</span>
          </Button>
        </TooltipWrapper>
      }
      content={
        <Button
          type="button"
          onClick={onConfirmClick}
          disabled={loading}
          primary
          {...confirmProps}
        >
          {confirmText}
        </Button>
      }
      position={confirmPosition}
      on="click"
    />
  );
};

type DeleteButton = {
  onConfirm: ConfirmButton['onConfirm'];
  loading: ConfirmButton['loading'];
};

const ConfirmProps = {negative: true, primary: false};

export const DeleteButton: React.FC<DeleteButton> = (props) => (
  <ConfirmButton
    {...props}
    text="Delete"
    loadingText="Deleting..."
    confirmText="Are you sure?"
    confirmPosition="right center"
    confirmProps={ConfirmProps}
    tertiary
    negative
  />
);
