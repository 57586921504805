import {analytics} from '../analytics';
import {ClientIds} from '../api/generated/enums';
import {Env} from '../config/env-vars';
import {notify} from '../hooks/use-subscription';
import {User} from '../types';
import {logger} from '../utils/logger';
import oidcClient, {
  UserManagerSettings,
  User as OidcUser,
  UserManager,
} from 'oidc-client';
import {once} from 'lodash';

const log = logger('oidc', {namespace: 'oidc-client'});
oidcClient.Log.logger = log;

type ClientSettings = {
  OrganizationSlug?: string;
};

export let userManager: UserManager;

export const initializeUserManager = once((clientSettings: ClientSettings) => {
  const settings = {
    client_id: ClientIds.web,
    authority: Env.apiBaseUrl || '',
    response_type: 'id_token token',
    scope: `openid profile elevator3.permissions Elevator3.Courses.Api`,
    redirect_uri: `${Env.appRoot}/callback`,
    filterProtocolClaims: true,
    post_logout_redirect_uri: Env.appRoot,
    silent_redirect_uri: `${Env.appRoot}/silent-refresh.html`,
    automaticSilentRenew: true,

    // Allow user clocks to be off by 1hr 5 minutes. We were running into issues where we
    // may have been missing payments due to user clocks being incorrect. The logic behind
    // 1hr 5 minutes is such that a user can either be under that time, but also handle
    // the scenario where the time is "correct" just missing the DST setting
    clockSkew: 60 * 65,
    extraQueryParams: clientSettings,
  } as UserManagerSettings;

  userManager = new UserManager(settings);

  userManager.events.addSilentRenewError(() => {
    analytics.trackEvent('identity.session-expired');
    notify('session-expired', undefined);
  });

  userManager.events.addUserSignedOut(async () => {
    await userManager.removeUser();
    window.location.reload();
  });
});

export const getUser = async (): Promise<OidcUser | null> => {
  const user = await userManager.getUser();
  return !user?.expired ? user : null;
};

export const mapOidcUser = (user: OidcUser): User => ({
  id: Number(user.profile.sub),
  name: user.profile.name || '',
  email: user.profile.preferred_username || '',
  permissions: user.profile.permissions,
  role: user.profile.role,
});
