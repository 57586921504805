import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {CourseDetails} from '../admin/courses/course-details/course-details';
import {CourseListing} from '../admin/courses/course-listing';
import {CourseResultListing} from '../admin/courses/course-result-listing';
import {SettingDetails} from '../settings/setting-details';
import {SettingListing} from '../settings/setting-listing';
import {UserCreate, UserDetails} from '../admin/users/user-create-update';
import {UserListing} from '../admin/users/user-listing';
import {routes} from './index';

const AdminRoutes = () => (
  <Switch>
    <Route path={routes.users.listing} exact>
      <UserListing />
    </Route>
    <Route path={routes.users.create} exact>
      <UserCreate />
    </Route>
    <Route path={routes.users.detail}>
      <UserDetails />
    </Route>
    <Route path={routes.settings.listing} exact>
      <SettingListing />
    </Route>
    <Route path={routes.settings.detail}>
      <SettingDetails />
    </Route>
    <Route path={routes.settings.detail}>
      <SettingDetails />
    </Route>
    <Route path={routes.courses.listing} exact>
      <CourseListing />
    </Route>
    <Route path={routes.courses.results}>
      <CourseResultListing />
    </Route>
    <Route path={routes.courses.detailBase}>
      <CourseDetails />
    </Route>
    <Route>
      <Redirect to={routes.courses.listing} />
    </Route>
  </Switch>
);

export default AdminRoutes;
