import * as Fields from './fields';

import {FieldCustomValue} from './adapters/custom-value';
import {Button} from './button';
import {FormContainer} from './container';
import {DebugFormState} from './debug-form-state';
import {FormRow} from './field-factory';
import {Form as BaseForm} from './form';
import {MultiSelectCheckbox} from './multi-select-checkbox';
import {FormSection} from './section';

type Form = typeof BaseForm & {
  Container: typeof FormContainer;
  Section: typeof FormSection;
  Row: typeof FormRow;

  Button: typeof Button;
  Checkbox: typeof Fields.FieldCheckbox;
  DatePicker: typeof Fields.FieldDatePicker;
  Dropdown: typeof Fields.FieldDropdown;
  Input: typeof Fields.FieldInput;
  InputCurrency: typeof Fields.FieldInputCurrency;
  InputDecimal: typeof Fields.FieldInputDecimal;
  InputFileUrl: typeof Fields.FieldInputFileUrl;
  InputMasked: typeof Fields.FieldInputMasked;
  RadioGroup: typeof Fields.FieldRadioGroup;
  TextArea: typeof Fields.FieldTextArea;

  CustomValue: typeof FieldCustomValue;
  Debug: typeof DebugFormState;
  MultiSelectCheckbox: typeof MultiSelectCheckbox;
};

export const Form = BaseForm as Form;

Form.Container = FormContainer;
Form.Section = FormSection;
Form.Row = FormRow;

Form.Button = Button;
Form.Checkbox = Fields.FieldCheckbox;
Form.Dropdown = Fields.FieldDropdown;
Form.Input = Fields.FieldInput;
Form.InputCurrency = Fields.FieldInputCurrency;
Form.InputDecimal = Fields.FieldInputDecimal;
Form.InputFileUrl = Fields.FieldInputFileUrl;
Form.InputMasked = Fields.FieldInputMasked;
Form.RadioGroup = Fields.FieldRadioGroup;
Form.TextArea = Fields.FieldTextArea;
Form.DatePicker = Fields.FieldDatePicker;

Form.CustomValue = FieldCustomValue;
Form.Debug = DebugFormState;
Form.MultiSelectCheckbox = MultiSelectCheckbox;
