import {set} from 'lodash';
import {StrictFormFieldProps} from 'semantic-ui-react';
import {CheckboxAdapterProps} from './adapters/checkbox';
import {DatePickerAdapterProps} from './adapters/datepicker';
import {DropdownAdapterProps} from './adapters/dropdown';
import {InputAdapterProps, InputMaskedAdapterProps} from './adapters/input';
import {RadioGroupAdapterProps} from './adapters/radio-group';
import {TextAreaAdapterProps} from './adapters/textarea';

type Input<T> = {
  fieldLabel?: string;
  fieldClassName?: string;
  fieldHint?: React.ReactNode;
  fieldWidth?: StrictFormFieldProps['width'];
  inputProps?: T;
  defaultValue?: any;
};

type Checkbox = Input<CheckboxAdapterProps>;
type Dropdown = Input<DropdownAdapterProps>;
type DatePicker = Input<DatePickerAdapterProps>;
type InputDefault = Input<InputAdapterProps>;
type InputMasked = Input<InputMaskedAdapterProps>;
type RadioGroup = Input<RadioGroupAdapterProps>;
type TextArea = Input<TextAreaAdapterProps>;

type FieldTypes =
  | Checkbox
  | DatePicker
  | Dropdown
  | InputDefault
  | InputMasked
  | RadioGroup
  | TextArea;

export type FieldConfig = {
  fieldName: string;
} & FieldTypes;

const $CONFIG = Symbol('$CONFIG');
const $VALUES = Symbol('$VALUES');

const config = <T>() => (config: T) => {
  config[$CONFIG] = $CONFIG;
  return (config as unknown) as FieldConfig;
};
export const currency = config<InputDefault>();
export const checkbox = config<Checkbox>();
export const datepicker = config<DatePicker>();
export const decimal = config<InputDefault>();
export const dropdown = config<Dropdown>();
export const input = config<InputDefault>();
export const masked = config<InputMasked>();
export const radiogroup = config<RadioGroup>();
export const textarea = config<TextArea>();

type PropertyMap = {[k: string]: FieldConfig};
type PropertyMapValue = {[k: string]: FieldConfig} | FieldConfig;

type AnyFunction = (...args: any[]) => any;
type TopLevelProperty =
  | number
  | string
  | boolean
  | symbol
  | undefined
  | null
  | void
  | AnyFunction
  | Date
  | Array<any>;

export type Cast<T, TComplex, TCastTo extends TComplex> = T extends object
  ? CastObject<T, TComplex, TCastTo>
  : T;

// prettier-ignore
export type CastObject<T, TComplex, TCastTo extends TComplex> = {
  [K in keyof T]: T[K] extends TopLevelProperty
    ? FieldConfig
    : T[K] extends TComplex
      ? TCastTo
      : Cast<T[K], TComplex, TCastTo>;
};

export type RawFieldConfig<T> = Cast<T, PropertyMapValue, PropertyMap>;

export function fieldConfig<T>(config: RawFieldConfig<T>) {
  config[$VALUES] = {};
  eachRecursive(config, config[$VALUES]);
  return config;
}

function eachRecursive(obj, defaultValues, keys: string[] = []) {
  if (obj[$CONFIG] === $CONFIG) {
    obj.fieldName = keys.join('.');

    if (obj.defaultValue) {
      set(defaultValues, obj.fieldName, obj.defaultValue);
    }

    delete obj[$CONFIG];
  } else {
    for (var k in obj) {
      if (typeof obj[k] == 'object' && obj[k] !== null) {
        eachRecursive(obj[k], defaultValues, [...keys, k]);
      }
    }
  }
}

export const getDefaults = <T>(config: RawFieldConfig<T>) => {
  return config[$VALUES];
};
