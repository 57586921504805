/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {routes} from './index';
import {AuthContext, userIsAdmin, useUser} from '../auth/use-auth';
import AdminRoutes from './admin-routes';
import OrganizationRoutes from './organization-routes';

export const Routes = () => {
  const user = useUser();

  return (
    <Switch>
      <Route path={routes.callback}>
        <Callback />
      </Route>
      {userIsAdmin(user) && (
        <Route path={routes.adminBase}>
          <AdminRoutes />
        </Route>
      )}
      <Route path={routes.organization.base}>
        <OrganizationRoutes />
      </Route>
      <Route path={routes.routeResolver} exact>
        <RouteResolver />
      </Route>
      <Redirect to={routes.routeResolver} />
    </Switch>
  );
};

const Callback = () => {
  const context = useContext(AuthContext);
  const redirect = context.redirectUrl ?? routes.routeResolver;

  return <Redirect to={redirect} />;
};

const RouteResolver = () => {
  const user = useUser();

  return userIsAdmin(user) ? (
    <Redirect to={routes.adminBase} />
  ) : (
    <Redirect to={routes.organization.landing} />
  );
};
