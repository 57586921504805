import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Header, Segment} from 'semantic-ui-react';
import {
  CoursesService,
  CourseSummaryDto,
  EnrollmentsService,
} from '../../api/generated';
import {useOrganizationSlug, userIsAdmin, useUser} from '../../auth/use-auth';

import {BasicPage} from '../../basic-page';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {notifications} from '../../utils/notification-service';
import {CompletionStatuses} from '../../api/generated/enums';

const CourseContentStart = () => {
  const organizationSlug = useOrganizationSlug();
  const history = useHistory();
  const match = useRouteMatch<{courseId: string; enrollmentId: string}>();
  const courseId = Number(match.params.courseId);
  const enrollmentId = Number(match.params.enrollmentId);
  const user = useUser();

  const [course, setCourse] = useState<CourseSummaryDto>();

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id: courseId});

    return data;
  }, [courseId]);

  useEffect(() => {
    if (fetchCourseSummary.value) {
      setCourse(fetchCourseSummary.value);
    }
  }, [fetchCourseSummary.value]);

  const startCourseContent = async () => {
    if (enrollmentId) {
      const response = await EnrollmentsService.updateContentStatus({
        id: enrollmentId,
        body: {
          contentStatus: CompletionStatuses['In Progress'],
          enrollmentId,
        },
      });

      if (response.hasErrors) {
        notifications.errors(response.errors);
      } else {
        history.push(
          buildPath(routes.organization.course.content, {
            slug: organizationSlug,
            courseId,
            enrollmentId,
          })
        );
      }
    }
  };

  const courseName = course?.name ?? '';
  const title = useMemo(
    () =>
      userIsAdmin(user)
        ? [
            {
              title: 'Return to Main Screen',
              url: routes.organization.landing,
            },
            {title: courseName},
          ]
        : [{title: courseName}],
    [courseName, user]
  );

  return (
    <BasicPage title={title} subTitle="Course Information">
      {course && (
        <Segment>
          <Header as="h3" content="Course Description" />
          <p>
            {course.description || 'This course does not have a description.'}
          </p>
          <Header as="h3" content="Course Terms" />
          <p>{course.courseTerms || 'This course does not have terms.'}</p>
          <Button
            primary
            content="Start Studying"
            onClick={startCourseContent}
          />
        </Segment>
      )}
    </BasicPage>
  );
};
export default CourseContentStart;
