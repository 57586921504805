import React, {useCallback} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {CoursesService} from '../../../../api/generated';
import {AsyncStateContainer} from '../../../../components/async-state-container';
import {Form} from '../../../../forms';
import {useNotification} from '../../../../hooks/use-notifications';
import {routes} from '../../../../routes';

const CourseConfiguration = () => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchCourseSummary = useAsync(async () => {
    const {data} = await CoursesService.getCourseSummaryById({id});
    return data;
  }, [id]);

  const onSubmit = useCallback(
    async (values) => {
      const response = await CoursesService.editCourseDetailsById({
        id,
        body: values,
      });

      if (response.hasErrors) {
        return response;
      }

      notifications.success('Course updated');
    },
    [id, notifications]
  );

  return (
    <AsyncStateContainer {...fetchCourseSummary}>
      {fetchCourseSummary.value && (
        <Form.Container>
          <Form
            initialValues={fetchCourseSummary.value}
            onSubmit={onSubmit}
            render={() => (
              <>
                <Form.Row
                  proportions={CourseConfiguration.ReadOnlyFieldsProportions}
                >
                  <Form.Input fieldName="name" fieldLabel="Name" readOnly />
                  <Form.Input fieldName="type" fieldLabel="Type" readOnly />
                </Form.Row>

                <Form.Row
                  proportions={CourseConfiguration.PriceFieldProportions}
                >
                  <Form.InputCurrency
                    type="number"
                    fieldName="priceInCents"
                    fieldLabel="Price"
                  />
                  <Form.Input
                    type="number"
                    fieldName="maxNumberOfAttempts"
                    fieldLabel="Max Number of Attempts"
                  />
                </Form.Row>

                <Form.Row>
                  <Form.TextArea
                    fieldName="description"
                    fieldLabel="Description"
                  />
                </Form.Row>

                <Form.Row>
                  <Form.TextArea
                    fieldName="courseTerms"
                    fieldLabel="Course Terms"
                  />
                </Form.Row>

                <Form.Row>
                  <Form.TextArea
                    fieldName="courseAssessmentTerms"
                    fieldLabel="Course Assessment Terms"
                  />
                </Form.Row>

                <Form.Row>
                  <Form.TextArea
                    fieldName="chapterAssessmentTerms"
                    fieldLabel="Chapter Assessment Terms"
                  />
                </Form.Row>

                <Form.Row
                  proportions={CourseConfiguration.PriceFieldProportions}
                >
                  <Form.InputDecimal
                    fieldName="finalAssessmentPassPercentage"
                    fieldLabel="Final Assessment Passing Grade"
                  />
                </Form.Row>

                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Course
                  </Form.Button>
                  <Form.Button as={Link} to={routes.courses.listing}>
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Form.Container>
      )}
    </AsyncStateContainer>
  );
};

CourseConfiguration.ReadOnlyFieldsProportions = [3, 1, 1];
CourseConfiguration.PriceFieldProportions = [1, 1, 2];

export default CourseConfiguration;
