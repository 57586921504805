import React from 'react';
import {CoursesService} from '../../api/generated';
import {BasicPage} from '../../basic-page';
import {routes} from '../../routes';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../../hooks/use-paged-data-table';

export const CourseListing = () => {
  const pagedDataTable = usePagedDataTable(
    CoursesService.getAllCourses,
    TableConfig
  );
  return <BasicPage title="Courses">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(CoursesService.getAllCourses, {
  columns: [
    {
      header: '',
      render: (item) =>
        renderEditButton({
          item,
          descriptor: 'course',
          route: routes.courses.detailBase,
        }),
      cellProps: {
        width: '1',
      },
    },
    {
      header: 'Name',
      column: 'name',
    },
    {
      header: 'Type',
      column: 'type',
    },
  ],
  defaultSort: {
    column: 'name',
    direction: 'ASC',
  },
  searchFieldNames: ['name'],
});
