import {css} from '@emotion/core';
import React from 'react';

const AssessmentAttemptInformation = ({
  currentQuestionNumber,
  totalQuestionCount,
}) => {
  const remainingQuestionsMessage = () =>
    totalQuestionCount
      ? `Question ${currentQuestionNumber} of ${totalQuestionCount}`
      : '';

  return (
    <div css={styles} className="assessment-information">
      <div className="remaining-questions-count">
        {remainingQuestionsMessage()}
      </div>
    </div>
  );
};

const styles = css`
  &.assessment-information {
    display: flex;
    justify-content: flex-end;
    font-size: 0.9em;

    .remaining-questions-count {
      margin-right: 20px;
    }
  }
`;

export default AssessmentAttemptInformation;
