import styled from '@emotion/styled/macro';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {UserPermissions} from '../api/generated/enums';
import {RequireAuthorization} from '../auth/require-authorization';
import {Tooltip} from '../components/tooltip';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';

const EnrolledCourseActions = ({enrollment, courseId, deleteEnrollment}) => {
  const [actionMessage, setActionMessage] = useState<string>('Details');
  const history = useHistory();

  useEffect(() => {
    if (enrollment) {
      setActionMessage(enrollment.isCompleted ? 'Results' : 'Continue');
    } else if (actionMessage !== 'Details') {
      setActionMessage('Details');
    }
  }, [enrollment, actionMessage]);

  const setRoute = async () => {
    let path = routes.organization.course.details;
    let assessmentAttemptId;

    if (enrollment) {
      if (enrollment.isCompleted) {
        path = routes.organization.course.enrollmentResults;
      } else if (enrollment.inProgressAssessmentAttemptId) {
        path = routes.organization.course.assessment;
        assessmentAttemptId = enrollment.inProgressAssessmentAttemptId;
      } else {
        path = routes.organization.course.content;
      }
    }

    history.push(
      buildPath(path, {
        courseId,
        enrollmentId: enrollment?.id,
        assessmentId: assessmentAttemptId,
      })
    );
  };

  return (
    <ActionContainer>
      <Button primary content={actionMessage} onClick={setRoute} />
      <RequireAuthorization permission={UserPermissions['Enrollments: Delete']}>
        {enrollment && (
          <Tooltip label="Delete Enrollment (For testing only)">
            <Button negative onClick={() => deleteEnrollment(enrollment.id)}>
              Delete
            </Button>
          </Tooltip>
        )}
      </RequireAuthorization>
    </ActionContainer>
  );
};

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;

  .button {
    flex: 1;
  }
`;

export default EnrolledCourseActions;
