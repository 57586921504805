import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {routes} from '../../../routes/index';
import CourseConfigurationDetails from './configuration-details/course-configuration-details';
import CourseAssessmentEditor from './assessment/course-assessment-editor';
import {CourseCategoryListing} from './categories/category-listing';
import {CourseCategoryCreate} from './categories/category-create';
import {CourseCategoryDetail} from './categories/category-detail';

const CourseContentEditor = React.lazy(
  () => import('./content/course-content-editor')
);

export const CourseDetailRoutes = () => (
  <Switch>
    <Route path={routes.courses.detailBase} exact>
      <CourseConfigurationDetails />
    </Route>
    <Route path={routes.courses.detail.assessment} exact>
      <CourseAssessmentEditor />
    </Route>
    <Route path={routes.courses.detail.categories.listing} exact>
      <CourseCategoryListing />
    </Route>
    <Route path={routes.courses.detail.categories.create} exact>
      <CourseCategoryCreate />
    </Route>
    <Route path={routes.courses.detail.categories.detail} exact>
      <CourseCategoryDetail />
    </Route>
    <Route path={routes.courses.detail.content} exact>
      <CourseContentEditor />
    </Route>
    <Route path={routes.courses.detailBase}>
      <Redirect to={routes.courses.detailBase} />
    </Route>
  </Switch>
);
