import styled from '@emotion/styled';
import React from 'react';
import {Logo} from '../assets/ldwf-logo';

export const SupportedBrowsersMessage = () => {
  return (
    <BrowserContainer>
      <Logo className="logo" />
      <div className="text">
        We're sorry, LDWF Education does not currently support Internet
        Explorer. Please consider using a modern browser, such as Google Chrome,
        Mozilla Firefox, or Microsoft Edge.
      </div>
    </BrowserContainer>
  );
};

const BrowserContainer = styled.div`
  padding-top: 100px;
  text-align: center;

  .text {
    width: 100%;
  }

  .logo {
    padding: 20px;
    width: 100%;
  }
`;
