import {css} from '@emotion/core';
import {lighten} from 'polished';
import React, {useCallback, useEffect, useState} from 'react';
import {useAsync} from 'react-use';
import {Card, Header} from 'semantic-ui-react';
import {
  CoursesService,
  CourseSummaryDto,
  EnrollmentsService,
  EnrollmentSummaryDto,
} from '../api/generated';
import {AsyncStateContainer} from '../components/async-state-container';
import EnrolledCourseActions from '../enrollments/enrolled-course-actions';
import {themeColors} from '../styles';

const UserLanding = () => {
  const [enrollments, setEnrollments] = useState<EnrollmentSummaryDto[]>([]);
  const [availableCourses, setAvailableCourses] = useState<CourseSummaryDto[]>(
    []
  );

  const fetchEnrollments = useCallback(async () => {
    const {
      data: enrollmentsData,
    } = await EnrollmentsService.getEnrollmentsSummaryByUserId();

    setEnrollments(enrollmentsData || []);

    return enrollmentsData;
  }, []);

  const fetchCoursesAndEnrollments = useAsync(async () => {
    const {data: courseData} = await CoursesService.getAllCourses();
    const enrollmentsData = await fetchEnrollments();

    return {courseData, enrollmentsData};
  }, [fetchEnrollments]);

  useEffect(() => {
    if (fetchCoursesAndEnrollments.value) {
      setAvailableCourses(
        fetchCoursesAndEnrollments.value?.courseData?.items || []
      );
    }
  }, [fetchCoursesAndEnrollments.value]);

  const deleteEnrollment = async (enrollmentId) => {
    var response = await EnrollmentsService.deleteEnrollmentById({
      id: enrollmentId,
    });

    if (!response.hasErrors) {
      fetchEnrollments();
    }
  };

  return (
    <div css={styles} className="container">
      <AsyncStateContainer {...fetchCoursesAndEnrollments}>
        {(availableCourses || []).map((course) => (
          <CourseCard
            course={course}
            enrollments={enrollments}
            deleteEnrollment={deleteEnrollment}
          />
        ))}
      </AsyncStateContainer>
    </div>
  );
};

const CourseCard = ({
  course,
  enrollments,
  deleteEnrollment,
}: {
  course: CourseSummaryDto;
  enrollments: EnrollmentSummaryDto[];
  deleteEnrollment: (enrollmentId: any) => Promise<void>;
}) => {
  return (
    <Card className="course-card" key={`course-${course.id}`}>
      <Header>
        <div className="course-information-cell">
          <div>{course.name}</div>
          <div className="course-details">{course.type}</div>
          <EnrolledCourseActions
            enrollment={enrollments.find((x) => x.courseId === course.id)}
            courseId={course.id}
            deleteEnrollment={deleteEnrollment}
          />
        </div>
      </Header>
    </Card>
  );
};

const styles = css`
  &.container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .course-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 30px 70px !important;
  }

  .course-information-cell {
    display: flex;
    flex-direction: column;

    .course-details {
      font-size: 0.85rem;
      color: ${lighten(0.2, themeColors.blue400)};
      margin-bottom: 8px;
    }
  }
`;

export default UserLanding;
