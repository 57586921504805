import React from 'react';
import {CategoriesService} from '../../../../api/generated';
import {BasicPage} from '../../../../basic-page';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../../../../hooks/use-paged-data-table';
import {Link, useRouteMatch} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {routes} from '../../../../routes';
import {buildPath} from '../../../../routes/utils';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';

export const CourseCategoryListing = () => {
  const match = useRouteMatch<{id: string}>();
  const courseId = Number(match.params.id);

  const TableConfig = React.useMemo(
    () =>
      PagedDataTableConfig(CategoriesService.getAll, {
        columns: [
          {
            header: '',
            render: (item) => (
              <>
                {renderEditButton({
                  item,
                  descriptor: 'Category',
                  route: routes.courses.detail.categories.detail,
                  additionalPathParams: {courseId},
                })}
              </>
            ),
            cellProps: {
              collapsing: true,
            },
          },
          {
            header: 'Category',
            column: 'name',
            sortable: 'name',
          },
          {
            header: 'Minimum Questions Count',
            column: 'minimumQuestionsCount',
            sortable: 'minimumQuestionsCount',
          },
        ],
        defaultSort: {
          column: 'name',
          direction: 'DESC',
        },
        searchFieldNames: ['name', 'minimumQuestionsCount'],
      }),
    [courseId]
  );

  const pagedDataTable = usePagedDataTable(
    CategoriesService.getAll,
    TableConfig,
    {
      additionalParams: {courseId},
      actions: (
        <Button
          as={Link}
          to={buildPath(routes.courses.detail.categories.create, {courseId})}
          primary
        >
          <FontAwesomeIcon icon={faPlus} /> New Category
        </Button>
      ),
    }
  );

  return <BasicPage title="Course Categories">{pagedDataTable}</BasicPage>;
};
