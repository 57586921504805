import css from '@emotion/css/macro';
import React, {useState} from 'react';
import {useMedia} from 'react-use';
import {Dropdown, Pagination} from 'semantic-ui-react';
import {useCachedState} from './use-cached-state';
import {Flex} from '../components/flex';
import {Media, MediaSizes} from '../styles/breakpoints';

type InitialPaginationState = {
  initialPage?: number;
  initialPageSize?: number;
};

type PaginationResult = {
  pageNumber: number;
  pageSize: number;
  render: (totalPages: number, totalRows: number) => React.ReactElement;
};

const defaultPageState = {
  initialPage: 1,
  initialPageSize: 25,
};

const pageSizeOptions = [
  {key: 1, text: '10', value: 10},
  {key: 2, text: '25', value: 25},
  {key: 3, text: '50', value: 50},
];

export const usePagination = (
  initialState?: InitialPaginationState
): PaginationResult => {
  const defaultState = initialState
    ? {
        ...defaultPageState,
        ...initialState,
      }
    : defaultPageState;

  const [pageNumber, setPageNumber] = useState(defaultState.initialPage);
  const [pageSize, setPageSize] = useCachedState(
    defaultState.initialPageSize,
    'lines-per-page'
  );

  const useSmallPagination = useMedia(`(${MediaSizes.MobileMax})`);
  const useMediumPagination = useMedia(`(${MediaSizes.TabletMax})`);

  let paginationProps = {};

  const render = (totalPages, totalRows) => {
    const maxPage = Math.min(pageNumber, totalPages);
    const pageStart = (maxPage - 1) * pageSize + 1;
    const pageStop = Math.min(maxPage * pageSize, totalRows);

    if (useMediumPagination) {
      paginationProps = {
        ...paginationProps,
        boundaryRange: 0,
        ellipsisItem: null,
      };
    }

    if (useSmallPagination) {
      paginationProps = {
        ...paginationProps,
        fluid: true,
        widths: totalPages > 3 ? 5 : totalPages + 2,
      };
    }

    return (
      <div css={styles} className="pagination">
        <Flex.Row align="center">
          <Flex.Fill>
            {totalPages > 0 && (
              <Pagination
                activePage={maxPage}
                totalPages={totalPages}
                onPageChange={(e, {activePage}) =>
                  setPageNumber(Number(activePage))
                }
                firstItem={null}
                lastItem={null}
                {...paginationProps}
              />
            )}
          </Flex.Fill>
          <Flex.Box>
            {totalRows > 0 && (
              <span className="lines-per-page">
                <span className="current-page">{`Showing ${pageStart} - ${pageStop} of ${totalRows}`}</span>
                <Dropdown
                  onChange={(e, {value}) => setPageSize(Number(value))}
                  options={pageSizeOptions}
                  selection
                  upward
                  compact
                  value={pageSize}
                />
              </span>
            )}
          </Flex.Box>
        </Flex.Row>
      </div>
    );
  };

  return {
    pageNumber,
    pageSize,
    render,
  };
};

const styles = css`
  .lines-per-page {
    display: inline-flex;
    align-items: center;

    ${Media('MobileMax')} {
      display: flex;

      .current-page {
        flex: 1 1 0%;
      }
    }

    .ui.dropdown {
      margin-left: 20px;
    }
  }
`;
