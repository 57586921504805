import React from 'react';
import moment from 'moment';

type DateProps = {
  date: number | Date | string;
  format?: string;
};

type TimeProps = {
  time: number | Date | string;
  format?: string;
};

export const DateTimeFormats = {
  Date: 'MM/DD/YY',
  Time: 'hh:mm A',
  DateTime: 'MM/DD/YY hh:mm A',
};

export const DateFormat = React.memo<DateProps>(
  ({date, format = DateTimeFormats.Date}) => <>{moment(date).format(format)}</>
);

export const TimeFormat = React.memo<TimeProps>(
  ({time, format = DateTimeFormats.Time}) => (
    <>{moment(time, 'LT').format(format)}</>
  )
);
