import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import {SkipNavContent, SkipNavLink} from '@reach/skip-nav';
import {cx} from 'emotion';
import React, {useCallback, useEffect, useState} from 'react';
import {ToastProvider} from 'react-toast-notifications';
import {Modal} from 'semantic-ui-react';
import {analytics} from './analytics';
import {setUser} from './analytics/sentry';
import {userManager} from './auth';
import {useUser} from './auth/use-auth';
import {NonProductionWarning} from './components/non-production-warning';
import {Env} from './config/env-vars';
import {useSubscription} from './hooks/use-subscription';
import {PrimaryNavigation} from './primary-navigation';
import {Routes} from './routes/routes';
import {LocationNotifier} from './routes/location-notifier';
import {themeColors} from './styles';
import {NotificationService} from './utils/notification-service';

export const App = () => {
  const user = useUser();
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    analytics.identify(`${user.id}`);
    setUser({id: `${user.id}`, email: user.email});
  }, [user.email, user.id]);

  useEffect(() => {
    userManager.clearStaleState();
  }, []);

  useSubscription('session-expired', () => {
    setSessionExpired(true);
    userManager.signoutRedirect();
  });

  const onLocationChange = useCallback(
    (location) => analytics.trackPage(location),
    []
  );

  return (
    <ToastProvider components={ToastComponents}>
      <NotificationService />
      <LocationNotifier onChange={onLocationChange} />
      <SkipNavLink>Main Content</SkipNavLink>
      <div
        className={cx('root-container', `${Env.name}-environment`)}
        css={styles}
      >
        <div className="main-content">
          <NonProductionWarning />
          <PrimaryNavigation user={user} />
          <div id="sub-content" className="sub-content">
            <SkipNavContent></SkipNavContent>
            <Routes />
          </div>
        </div>
      </div>

      <Modal open={sessionExpired}>
        <Modal.Header>Session Expired</Modal.Header>
      </Modal>
    </ToastProvider>
  );
};

const styles = css`
  &.root-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    background-color: ${themeColors.gray50};
  }

  .main-content {
    flex: 1 1 0%;
    overflow-x: hidden;
    display: flex;
    position: relative;
    background: ${themeColors.green900};
    box-shadow: rgb(87, 99, 105) -1px 2px 4px -3px;
    border-top: none;
    flex-direction: column;

    > .sub-content {
      flex: 1 1 0%;
      overflow-y: auto;
      background-color: ${themeColors.gray50};
      position: relative;
    }
  }

  &.production-environment .main-content {
    border-top: solid 4px ${themeColors.gray600};
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  top: 70px;
  right: 8px;
  max-height: 100%;
  z-index: 10001;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
  pointer-events: none;
`;

const ToastComponents = {ToastContainer};
