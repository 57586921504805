import * as Sentry from '@sentry/browser';
import {Env} from '../config/env-vars';

const {isProductionBuild, sentryKey, sentryProjectId} = Env;

const shouldEnableSentry =
  isProductionBuild && !!sentryKey && !!sentryProjectId;

if (shouldEnableSentry) {
  const regexMixpanel = /mixpanel/gi;
  const excludedHttpBreadcrumbs = [regexMixpanel];

  Sentry.init({
    dsn: `https://${sentryKey}@sentry.io/${sentryProjectId}`,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr' && breadcrumb.data) {
        const {url} = breadcrumb.data;
        if (excludedHttpBreadcrumbs.some((regex) => regex.test(url))) {
          return null;
        }
      }
      return breadcrumb;
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded', // https://stackoverflow.com/a/50387233 All good man.
      'SilentRenewService._tokenExpiring: Error from signinSilent:',
      'Frame window timed out',
      'Callback Error',
      'CheckSessionIFrame: error message from check session op iframe',
    ],
    release: Env.buildNumber,
    environment: Env.name,
  });
}

export const setUser = ({id, email}: {id?: string; email?: string}) => {
  if (shouldEnableSentry) {
    Sentry.setUser({id, email});
  }
};
